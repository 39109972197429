import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    routines: [],
    routinesListenerInitialised: false
};

export const routinesSlice = createSlice({
    name: 'routinesListener',
    initialState,
    reducers: {
        setRoutines: (state, action) => {
            state.routines = action.payload
        },
        setRoutineListenerInitialised: (state, action) => {
            state.routinesListenerInitialised = action.payload
        }
    },
});

export const {setRoutines} = routinesSlice.actions;
export const {setRoutineListenerInitialised} = routinesSlice.actions;
export const selectRoutines = (state) => state.routines;

export default routinesSlice.reducer;
