import {initializeApp } from '@firebase/app';
import {
    browserLocalPersistence,
    getAuth,
    setPersistence,
    signInWithEmailAndPassword,
    updatePassword,
    sendPasswordResetEmail,
    EmailAuthProvider,
    reauthenticateWithCredential
} from '@firebase/auth';
import {collection, doc, getDoc, getFirestore, setDoc} from '@firebase/firestore';
import config from '../App.config';
import {store} from '../redux/Store';
import React from 'react';
import i18n from '../components/i18n';

//-----Listeners---------------------------------------------------
import {startExercisesListener} from "./Exercises";
import {startExercisesLibraryListener} from './ExercisesLibrary'

import {startWorkoutsListener} from "./Workouts";
import { startWorkoutsLibraryListener } from './WorkoutsLibrary';

import {startAssetsListener} from "./Assets";
import {startAssetsLibraryListener} from "./AssetsLibrary"

import {startRoutinesListener} from "./Routines";
import {startRoutinesLibraryListener} from "./RoutinesLibrary"

import {startUserListener} from "./User";
import { startAthletesListener } from './Athletes';
import {startTagsListener} from "./Tags";

//-----Reducers func---------------------------------------------------
import {setExerciseListenerInitialised, setExercises} from "../redux/ExercisesReducer";
import {setExerciseLibraryListenerInitialised, setExercisesLibrary} from "../redux/ExercisesLibraryReducer";

import {setWorkouts, setWorkoutsListenerInitialised} from "../redux/WorkoutsReducer";
import { setWorkoutsLibrary, setWorkoutsLibraryListenerInitialised } from '../redux/WorkoutsLibraryReducer';

import {setAssets, setAssetsListenerInitialised} from "../redux/AssetsReducer";
import {setAssetsLibrary, setAssetsLibraryListenerInitialised} from "../redux/AssetsLibraryReducer"

import {setRoutineListenerInitialised, setRoutines} from "../redux/RoutinesReducer";
import {setRoutineLibraryListenerInitialised, setRoutinesLibrary} from "../redux/RoutinesLibraryReducer";

import {setUserListenerInitialised, setUser, setUserMeta} from '../redux/UserReducer';

import { setPlans, setPlansListenerInitialised } from '../redux/PlansReducer';
import {setPlansLibrary, setPlansLibraryListenerInitialised} from '../redux/PlansLibraryReducer'

import { setAthletes, setAthletesListenerInitialised } from '../redux/AthletesReducer';


class MyFirebase {
    constructor() {
        this.app = initializeApp(config);
        this.user = {}
        this.unsubscribeFunctions = {};

        getAuth().onAuthStateChanged(async u => {
            if (u) {
                const user = {}
                user.uid = u.uid;
                user.meta = await getUserMeta(u.uid);
                user.settings = {}
                user.settings.langs = await getUserLangs(u.uid)
                user.settings.uiLang = await getUserUILang(u.uid)
                console.debug('FB onAuthStateChanged', user);
                Firebase.user = user;
                i18n.changeLanguage(user.settings.uiLang)
                store.dispatch(setUserMeta(user))
                store.dispatch(setUser(user))
                startUserListener(u.uid, (key, unsubscribeFun) => {
                    this.unsubscribeFunctions[key] = unsubscribeFun
                })
                startTagsListener(u.uid, (key, unsubscribeFun) => {
                    this.unsubscribeFunctions[key] = unsubscribeFun
                })
                startExercisesListener(u.uid, (key, unsubscribeFun) => {
                    this.unsubscribeFunctions[key] = unsubscribeFun
                })
                startExercisesLibraryListener(u.uid, (key, unsubscribeFun) => {
                    this.unsubscribeFunctions[key] = unsubscribeFun
                })
                startWorkoutsListener(u.uid, (key, unsubscribeFun) => {
                    this.unsubscribeFunctions[key] = unsubscribeFun
                })
                startWorkoutsLibraryListener(u.uid, (key, unsubscribeFun) => {
                    this.unsubscribeFunctions[key] = unsubscribeFun
                })
                startAssetsListener(u.uid, (key, unsubscribeFun) => {
                    this.unsubscribeFunctions[key] = unsubscribeFun
                })
                startAssetsLibraryListener(u.uid, (key, unsubscribeFun) => {
                    this.unsubscribeFunctions[key] = unsubscribeFun
                })
                startRoutinesListener(u.uid, (key, unsubscribeFun) => {
                    this.unsubscribeFunctions[key] = unsubscribeFun
                })
                startRoutinesLibraryListener(u.uid, (key, unsubscribeFun) => {
                    this.unsubscribeFunctions[key] = unsubscribeFun
                })
                startAthletesListener(u.uid, (key, unsubscribeFun) => {
                    this.unsubscribeFunctions[key] = unsubscribeFun
                })
            } else {
                Object.values(this.unsubscribeFunctions).forEach(unsubscribeFun => unsubscribeFun());
                this.unsubscribeFunctions = {};
                Firebase.user = undefined;

                store.dispatch(setExercises([]));
                store.dispatch(setExerciseListenerInitialised(false));
                store.dispatch(setExercisesLibrary([]));
                store.dispatch(setExerciseLibraryListenerInitialised(false));

                store.dispatch(setWorkouts([]));
                store.dispatch(setWorkoutsListenerInitialised(false));
                store.dispatch(setWorkoutsLibrary([]));
                store.dispatch(setWorkoutsLibraryListenerInitialised(false));

                store.dispatch(setAssets([]));
                store.dispatch(setAssetsListenerInitialised(false));
                store.dispatch(setAssetsLibrary([]));
                store.dispatch(setAssetsLibraryListenerInitialised(false));

                store.dispatch(setPlans([]));
                store.dispatch(setPlansListenerInitialised(false));
                store.dispatch(setPlansLibrary([]));
                store.dispatch(setPlansLibraryListenerInitialised(false));

                store.dispatch(setRoutines([]));
                store.dispatch(setRoutineListenerInitialised(false));
                store.dispatch(setRoutinesLibrary([]));
                store.dispatch(setRoutineLibraryListenerInitialised(false));

                store.dispatch(setUser({}));
                store.dispatch(setUserListenerInitialised(false));
                store.dispatch(setAthletes({}));
                store.dispatch(setAthletesListenerInitialised(false));
            }
        })
        setPersistence(getAuth(), browserLocalPersistence)
    }
}

const Firebase = new MyFirebase();


/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */


export const doSignInWithEmailAndPassword = async (email, password) => {
    Firebase.user = {}
    return await signInWithEmailAndPassword(getAuth(), email, password).then(async uc => {
        console.debug('User logged into FB')
    })
}

export const doSignOut = async () => {
    console.debug('Signing out from FB')
    await getAuth().signOut()
}

export const changePassword = async (oldPassword, newPassword) => {
    const user = getAuth().currentUser
    const credential = EmailAuthProvider.credential(user.email, oldPassword);
    await reauthenticateWithCredential(user, credential);
    await updatePassword(user, newPassword);
}
    

export const sendResetEmail = async (email) => {
    return await sendPasswordResetEmail(getAuth(), email);
}

const getUserMeta = async (id) => {
    const ref = doc(getFirestore(), `${config.FIRESTORE_ROOT}/usersmeta/${id}`)
    const snap = await getDoc(ref);
    return snap.data();
}

const getUserLangs = async (id) => {
    const ref = doc(getFirestore(), `${config.FIRESTORE_ROOT}/usersdata/${id}/mysettings/yhrs-general`)
    const snap = await getDoc(ref);
    const d = snap.data();
    return d?.contentLangs || ['en'];
}

const getUserUILang = async (id) => {
    const ref = doc(getFirestore(), `${config.FIRESTORE_ROOT}/usersdata/${id}/mysettings/yhrs-general`)
    const snap = await getDoc(ref);
    const d = snap.data();
    return d?.uiLang || 'en';
}

export const setUserUILang = async (id, uiLang) => {
    i18n.changeLanguage(uiLang)
    Firebase.user.settings = {...Firebase.user.settings, uiLang: uiLang}
    const ref = doc(getFirestore(), `${config.FIRESTORE_ROOT}/usersdata/${id}/mysettings/yhrs-general`)
    await setDoc(ref, { uiLang: uiLang }, { merge: true });
}

export const requestBeta = async (data) => {
    const path = `${config.FIRESTORE_ROOT}/helpdeskqueries`
    const ref = doc(collection(getFirestore(),path))
    await setDoc(ref, data)
}

/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */

/* ------------------------------------------------------------------------------------ */


export function useTranslatedData(obj) {
    const [data, setData] = React.useState(obj);

    const getMandatoryUiLangProp = (propName) => {
        if (!data) return '';
        const lang = Firebase?.user?.settings?.langs?.[0] || 'en';
        const def = data[propName];

        if (lang) {
            const p = (data?.i18n || {})[propName];
            if (!p) return def;
            const t = (p || {})[lang];
            if (t === undefined) return def;
            return t;
        } else {
            return def;
        }
    };

    const getTranslatedProp = (propName, lang) => {
        if (!data) return '';
        if (lang) {
            const p = (data?.i18n || {})[propName]
            if (!p) return ''
            const t = (p || {})[lang];
            if (t === undefined) return ''
            return t
        } else {
            return ''
        }
    };

    const setTranslatedProp = (propName, val, lang) => {
        if (!data) return '';
        if (lang === Firebase?.user?.settings?.langs?.[0] || lang === undefined) {
            lang = Firebase?.user?.settings?.langs?.[0] || 'en';
            data[propName] = val;
        }
        data.i18n = data.i18n || {}
        data.i18n[propName] = data.i18n[propName] || {}
        data.i18n[propName][lang] = val;

        setData({...data})
        return val;
    }

    // tags helper functions
    const setTranslatedPropOnly = (propName, val, lang) => {
        if (!data) return '';
        data.i18n = data.i18n || {}
        data.i18n[propName] = data.i18n[propName] || {}
        data.i18n[propName][lang] = val;
        setData({...data})
        return val;
    }

    const setRootProp = (propName, val) => {
        data[propName] = val;
        setData({...data})
        return val;
    }

    return [data, setData, getMandatoryUiLangProp, getTranslatedProp, setTranslatedProp, setTranslatedPropOnly, setRootProp];
}


/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------ */

// !!! for the changed language it would still show the root prop
export const getTranslatedProp2 = (data, propName, lang) => {
    if (!data) return '';
    const def = data[propName]
    if (lang === undefined) {
        return def
    } else if (lang) {
        const p = (data?.i18n || [])[propName]
        if (!p) return def
        const t = (p || [])[lang];
        if (t === undefined) return def
        return t
    } else {
        return def
    }
}

export const getMandatoryUiLangProp2 = (data, propName) => {
    if (!data) return '';
    const lang = Firebase?.user?.settings?.langs?.[0] || 'en';
    const def = data[propName] || '';
    if (lang === undefined) {
        return def
    } else if (lang) {
        const p = (data?.i18n || [])[propName];
        if (!p)return def
        const t = (p || [])[lang];
        if (t === undefined) return def
        return t
    } else {
        return def
    }
}

// get translation for given lang or empty string // it should be used for OTHER than main prop edit text in forms
export const getTranslatedPropOrEmptyString = (data, propName, lang) => {
    if (!data) return '';
    const def = '';
    if (lang === undefined) {
        return def
    } else if (lang) {
        const p = (data?.i18n || [])[propName]
        if (!p) return def
        const t = (p || [])[lang];
        if (t === undefined) return def
        return t
    } else {
        return def
    }
}

export function makeid(length = 16) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export default Firebase;