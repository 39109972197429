import config from '../App.config';
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
    updateDoc
} from '@firebase/firestore';
import * as yup from 'yup';
import {getMandatoryUiLangProp2, makeid} from './Firebase'
import {store} from "../redux/Store";
import {setWorkouts, setWorkoutsListenerInitialised} from "../redux/WorkoutsReducer";
import { fetchAsset } from './Assets';
import { overwriteAllRoutinesWithFullExercises, cleanRoutine } from './Routines';

export const startWorkoutsListener = (uid, setUnsubscribe) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myworkoutspreview`;
    const ref = collection(getFirestore(), path)
    const q = query(ref);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const workouts = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();

            let parsedWorkout = {
                id: doc.id || '',
                name: data.name || '',
                tagIds: data.tagIds || [],
            };

            if (data.i18n && data.i18n.name) {
                parsedWorkout.i18n = {name: data.i18n.name};
            }

            workouts.push(parsedWorkout);
        });

        workouts.sort((a, b) => {
            const nameA = getMandatoryUiLangProp2(a, 'name');
            const nameB = getMandatoryUiLangProp2(b, 'name');
            return nameA.localeCompare(nameB);
        });

        setUnsubscribe("WORKOUTS_UNSUB_FUN", unsubscribe);
        store.dispatch(setWorkouts(workouts));
        store.dispatch(setWorkoutsListenerInitialised(true));
    });
}

// ---  ------------------------------------------------------------------
export const getMyWorkouts = async (uid) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myworkouts`;
    const ref = collection(getFirestore(), path)
    const q = await query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
        return {...doc.data(), id: doc.id}
    })
}


// ---  ------------------------------------------------------------------
export const getMyWorkoutsPage = async (uid, last) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myworkouts`;
    const ref = collection(getFirestore(), path)
    let q;
    if (last) {
        q = await query(ref, orderBy('name'), startAfter(last), limit(20));
    } else {
        q = await query(ref, orderBy('name'), limit(20));
    }
    const querySnapshot = await getDocs(q);
    const _last = (querySnapshot.size > 0) ? querySnapshot.docs[querySnapshot.size - 1] : null
    const data = querySnapshot.docs?.map(doc => {
        return {...doc.data(), id: doc.id}
    })
    return {data, last: _last};
}


// ---  ------------------------------------------------------------------
export const getMyWorkout = async (uid, id, tagsSelector) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myworkouts/${id}`;
    const ref = doc(getFirestore(), path)
    const snap = await getDoc(ref);
    if (snap.data()) {
        const fullTags = tagsSelector?.tags?.filter(tag => snap.data().tagIds?.includes(tag.id));
        const ret = {...emptyWorkout(), ...snap.data(), id: snap.id, tags: fullTags}
        ret.routines.forEach(routineData => {
            if (['seriesv2'].includes(routineData.type)) {
                routineData.series.forEach(serie => {
                    serie.exercise.tags = tagsSelector?.tags.filter(tag => serie.exercise.tagIds?.includes(tag.id));
                })
            }
            if(['circuitv2'].includes(routineData.type)){
                routineData.exercises.forEach(exercise => {
                    exercise.tags = tagsSelector?.tags.filter(tag => exercise.tagIds?.includes(tag.id));
                })
            }
            if(['tabatav2'].includes(routineData.type)){
                routineData.rounds.forEach(round => {
                    round.exercise.tags = tagsSelector?.tags.filter(tag => round.exercise.tagIds?.includes(tag.id));
                })
            }
            if(['pyramidv2'].includes(routineData.type)){
                routineData.exercise.tags = tagsSelector?.tags.filter(tag => routineData.exercise.tagIds?.includes(tag.id));
            }
        });
        addGuidsTo(ret)
        repairSchema(ret)
        return ret;
    } else {
        throw new Error("Entity not found");
    }
}

// ---  ------------------------------------------------------------------
export const addGuidsTo = async (workout) => {
    workout.routines.forEach(r => {
        r.guid = makeid()
        r.exercises?.forEach(item => item.guid = makeid())
        r.punishment?.forEach(item => item.guid = makeid())
        r.round?.forEach(item => item.guid = makeid())
        r.rounds?.forEach(item => item.guid = makeid())
        r.series?.forEach(item => item.guid = makeid())
    });
}

const repairSchema = (w) => {
    w.tags = [...w.tags]
}

// ---  ------------------------------------------------------------------
export const putMyWorkout = async (uid, data, newid) => {
    data.id = newid ? makeid() : (data.id || makeid());
    const _data = cleanWorkout(data);
    _data.tagIds = _data.tags ? _data.tags.map(tag => tag.id) : [];

    const previewData = {
        name: _data.name,
        tagIds: _data.tagIds,
    };

    if (_data.i18n && _data.i18n.name) {
        previewData.i18n = {name: _data.i18n.name};
    }

    if (_data.heroAsset && _data.heroAsset.id) {
        const heroAsset = await fetchAsset(uid, _data.heroAsset.id);
        if (heroAsset) _data.heroAsset = heroAsset;
    }

    if (Array.isArray(_data.tutorials)) {
        _data.tutorials = await Promise.all(_data.tutorials.map(async (item) => {
            if (item.id) return fetchAsset(uid, item.id);
            return null;
        })).then(results => results.filter(item => item !== null));
    }

    if (Array.isArray(_data.extras)) {
        _data.extras = await Promise.all(_data.extras.map(async (item) => {
            if (item.id) return fetchAsset(uid, item.id);
            return null;
        })).then(results => results.filter(item => item !== null));
    }

    await overwriteAllRoutinesWithFullExercises(uid, _data);

    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myworkouts/${_data.id}`;
    const ref = doc(getFirestore(), path)

    const workoutPreviewPath = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myworkoutspreview/${_data.id}`;
    const workoutPreviewRef = doc(getFirestore(), workoutPreviewPath)

    delete _data.tags;
    delete previewData.tags;

    await setDoc(ref, _data, {merge: true})
    await setDoc(workoutPreviewRef, previewData, {merge: true})
}

// ---  ------------------------------------------------------------------
export const removeMyWorkout = async (uid, id) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myworkouts/${id}`
    const ref = doc(getFirestore(), path)

    const workoutPreviewPath = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myworkoutspreview/${id}`;
    const workoutPreviewRef = doc(getFirestore(), workoutPreviewPath)

    console.debug(`removeMyWorkout: ${id}`)

    deleteDoc(ref)
    deleteDoc(workoutPreviewRef)
}


// ---  ------------------------------------------------------------------
export const getWorkoutTypes = () => {
    return ['workout', 'challenge'];
}


// ---  ------------------------------------------------------------------
export const emptyWorkout = () => {
    return {
        name: '',
        description: '',
        type: 'workout',
        tagIds: [],
        heroAsset: null,
        tutorials: [],
        extras: [],
        routines: [{
            guid: makeid(),
            type: 'seriesv2',
            exercises: [{guid: makeid(), type: 'duration', tutorials: [], tags: []}],
            punishment: [{guid: makeid(), type: 'duration', tutorials: [], tags: []}],
            challenge: {guid: makeid(), type: 'duration', tutorials: [], tags: []},
            round: [{guid: makeid(), type: 'duration', tags: []}],
            rounds: [{guid: makeid(), exercise: {guid: makeid(), type: 'duration', tutorials: [], tags: []}}],
            series: [{guid: makeid(), exercise: {guid: makeid(), type: 'duration', tutorials: [], tags: []}}],
            loop: false
        }],
        // local purposes only
        tags: []
    }
}


// ---  ------------------------------------------------------------------
export const workoutSchema = (t) => yup
    .object()
    .shape({
        name: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')).max(255, t('Maximum 255 znaków')),
        description: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')).max(1000, t('Maksimum 1000')),
        routines: yup.array().of(
            yup.object().shape({
                name: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')),
                // description: yup.string().required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')).max(1000, t('Maksimum 1000')),
                type: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')),
                timeLimit: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                        return (type === 'amrapv2') ? yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(1000, t('Maksimum 1000')) : schema
                    }
                ),
                numOfRounds: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                        return (type === 'circuitv2' || type === 'emomv2') ? yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(1000, t('Maksimum 1000')) : schema
                    }
                ),
                roundDuration: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                        return (type === 'emomv2') ? yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(1000, t('Maksimum 1000')) : schema
                    }
                ),
                // breakDuration: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                //         return (type === 'circuitv2') ? yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(1000, t('Maksimum 1000')) : schema
                //     }
                // ),
                startReps: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                        return (type === 'wworkoutv2' || type === 'pyramidv2') ? yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(1000, t('Maksimum 1000')) : schema
                    }
                ),
                endReps: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                        return (type === 'wworkoutv2' || type === 'pyramidv2') ? yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(1000, t('Maksimum 1000')) : schema
                    }
                ),
                progress: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                        return (type === 'wworkoutv2' || type === 'pyramidv2') ? yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(1000, t('Maksimum 1000')) : schema
                    }
                ),

                // -----
                exercises: yup.array().when('type', ([type], schema) => {
                    return (type === 'circuitv2') ? yup.array().of(yup.object().shape({
                        name: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')),
                        type: yup.string(t('Pole jest wymagane')),
                        // load: yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(10000, t('Maksimum 10000')),
                        //breakDuration: yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(86400, t('Maksimum 86400')),
                        // reps: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                        //         return (type === 'reps') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(10000, t('Maksimum 10000')) : schema
                        //     }
                        // ),
                        // duration: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                        //         return (type === 'duration') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(86400, t('Maksimum 86400')) : schema
                        //     }
                        // ),
                        // max: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                        //         return (type === 'max') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(100, t('Maksimum 100')) : schema
                        //     }
                        // ),
                    })) : undefined
                }),
                exercise: yup.object().when('type', ([type], schema) => {
                        switch (type) {
                            case 'wworkoutv2':
                            case 'pyramidv2':
                                return yup.object().shape({
                                    name: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')),

                                })
                        }
                    }
                ),

                // -----
                round: yup.array().when('type', ([type], schema) => {
                        switch (type) {
                            case 'emomv2':
                            case 'amrapv2':
                                return yup.array().of(yup.object().shape({
                                    name: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')),
                                    type: yup.string(t('Pole jest wymagane')),
                                    // load: yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(10000, t('Maksimum 10000')),
                                    reps: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                            return (type === 'reps') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(10000, t('Maksimum 10000')) : schema
                                        }
                                    ),
                                    duration: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                            return (type === 'duration') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(86400, t('Maksimum 86400')) : schema
                                        }
                                    ),
                                    max: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                            return (type === 'max') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(100, t('Maksimum 100')) : schema
                                        }
                                    ),
                                }))
                        }
                    }
                ),
                // -----
                challenge: yup.object().when('type', ([type], schema) => {
                    return (type === 'punisher') ? yup.object().shape({
                        name: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')),
                        type: yup.string(t('Pole jest wymagane')),
                        // load: yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(10000, t('Maksimum 10000')),
                        reps: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                return (type === 'reps') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(10000, t('Maksimum 10000')) : schema
                            }
                        ),
                        duration: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                return (type === 'duration') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(86400, t('Maksimum 86400')) : schema
                            }
                        ),
                        max: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                return (type === 'max') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(100, t('Maksimum 100')) : schema
                            }
                        ),
                    }) : undefined
                }),

                // -----
                punishment: yup.array().when('type', ([type], schema) => {
                        return (type === 'punisher') ? yup.array().of(yup.object().shape({
                            name: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')),
                            type: yup.string(t('Pole jest wymagane')),
                            // load: yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(10000, t('Maksimum 10000')),
                            reps: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                    return (type === 'reps') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(10000, t('Maksimum 10000')) : schema
                                }
                            ),
                            duration: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                    return (type === 'duration') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(86400, t('Maksimum 86400')) : schema
                                }
                            ),
                            max: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                    return (type === 'max') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(100, t('Maksimum 100')) : schema
                                }
                            ),
                        })) : undefined
                    }
                ),
                // -----
                series: yup.array().when('type', ([type], schema) => {
                        return (type === 'seriesv2') ? yup.array().of(yup.object().shape({
                            // breakDuration: yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(1000, t('Maksimum 1000')),
                            exercise: yup.object().shape({
                                name: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')),
                                type: yup.string(t('Pole jest wymagane')),
                                // load: yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(10000, t('Maksimum 10000')),
                                // reps: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                //         return (type === 'reps') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(10000, t('Maksimum 10000')) : schema
                                //     }
                                // ),
                                // duration: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                //         return (type === 'duration') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(86400, t('Maksimum 86400')) : schema
                                //     }
                                // ),
                                // max: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                //         return (type === 'max') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(100, t('Maksimum 100')) : schema
                                //     }
                                // ),
                            })
                        })) : undefined
                    }
                ),
                // -----
                rounds: yup.array().when('type', ([type], schema) => {
                        return (type === 'tabatav2') ? yup.array().of(yup.object().shape({
                            breakDuration: yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(1000, t('Maksimum 1000')),
                            exercise: yup.object().shape({
                                name: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')),
                                type: yup.string(t('Pole jest wymagane')),
                                // load: yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(10000, t('Maksimum 10000')),
                                // reps: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                //         return (type === 'reps') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(10000, t('Maksimum 10000')) : schema
                                //     }
                                // ),
                                // duration: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                //         return (type === 'duration') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(86400, t('Maksimum 86400')) : schema
                                //     }
                                // ),
                                // max: yup.number(t('Pole jest wymagane')).when('type', ([type], schema) => {
                                //         return (type === 'max') ? schema.required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(100, t('Maksimum 100')) : schema
                                //     }
                                // ),
                            })
                        })) : undefined
                    }
                ),
                // -----
            })
        )
    })
    .required();


// --- clean workout object (before saving in db) ------------------------------------------------------------------

export const cleanWorkout = (w) => {
    const ret = {...w};
    console.log("test123 ret",ret)
    ret.routines.forEach((r, i) => {
        // it includes routine tags -> tagIds handling
        cleanRoutine(r);
    })

    console.debug('Cleaned Workout data:', ret);
    return ret;
}



