import config from '../App.config';
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
    updateDoc
} from '@firebase/firestore';
import {getMandatoryUiLangProp2, makeid} from './Firebase'
import {store} from "../redux/Store";
import { setWorkoutsLibrary, setWorkoutsLibraryListenerInitialised }
from '../redux/WorkoutsLibraryReducer';

export const startWorkoutsLibraryListener = (uid, setUnsubscribe) => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/workouts/`;
    const ref = collection(getFirestore(), path)
    const q = query(ref);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const workouts = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();

            let parsedWorkout = {
                id: doc.id || '',
                name: data.name || '',
                tags: data.tags || [],
            };

            if (data.i18n && data.i18n.name) {
                parsedWorkout.i18n = {name: data.i18n.name};
            }

            workouts.push(parsedWorkout);
        });

        workouts.sort((a, b) => {
            const nameA = getMandatoryUiLangProp2(a, 'name');
            const nameB = getMandatoryUiLangProp2(b, 'name');
            return nameA.localeCompare(nameB);
        });

        setUnsubscribe("WORKOUTS_UNSUB_FUN", unsubscribe);
        store.dispatch(setWorkoutsLibrary(workouts));
        store.dispatch(setWorkoutsLibraryListenerInitialised(true));
    });
}

// ---  ------------------------------------------------------------------
export const getMyWorkouts = async () => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/workouts`;
    const ref = collection(getFirestore(), path)
    const q = await query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
        return {...doc.data(), id: doc.id}
    })
}

// ---  ------------------------------------------------------------------
export const getMyWorkoutsPage = async (last) => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/workouts`;
    const ref = collection(getFirestore(), path)
    let q;
    if (last) {
        q = await query(ref, orderBy('name'), startAfter(last), limit(20));
    } else {
        q = await query(ref, orderBy('name'), limit(20));
    }
    const querySnapshot = await getDocs(q);
    const _last = (querySnapshot.size > 0) ? querySnapshot.docs[querySnapshot.size - 1] : null
    const data = querySnapshot.docs?.map(doc => {
        return {...doc.data(), id: doc.id}
    })
    return {data, last: _last};
}

// ---  ------------------------------------------------------------------
export const getMyWorkout = async (id) => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/webapp/workouts/${id}`;
    const ref = doc(getFirestore(), path)
    const snap = await getDoc(ref);
    if (snap.data()) {
        const ret = {...emptyWorkout(), ...snap.data(), id: snap.id}
        addGuidsTo(ret)
        repairSchema(ret)
        return ret;
    } else {
        throw new Error("Entity not found");
    }
}

// ---  ------------------------------------------------------------------
export const addGuidsTo = async (workout) => {
    workout.routines.forEach(r => {
        r.guid = makeid()
        r.exercises?.forEach(item => item.guid = makeid())
        r.punishment?.forEach(item => item.guid = makeid())
        r.round?.forEach(item => item.guid = makeid())
        r.rounds?.forEach(item => item.guid = makeid())
        r.series?.forEach(item => item.guid = makeid())
    });
}

const repairSchema = (w) => {
    w.tags = [...w.tags]
}

// ---  ------------------------------------------------------------------
export const getWorkoutTypes = () => {
    return ['workout', 'challenge'];
}


// ---  ------------------------------------------------------------------
export const emptyWorkout = () => {
    return {
        name: '',
        description: '',
        type: 'workout',
        tags: [],
        heroAsset: null,
        tutorials: [],
        extras: [],
        routines: [{
            guid: makeid(),
            type: 'seriesv2',
            exercises: [{guid: makeid(), type: 'duration', tutorials: [], tags: []}],
            punishment: [{guid: makeid(), type: 'duration', tutorials: [], tags: []}],
            challenge: {guid: makeid(), type: 'duration', tutorials: [], tags: []},
            round: [{guid: makeid(), type: 'duration', tags: []}],
            rounds: [{guid: makeid(), exercise: {guid: makeid(), type: 'duration', tutorials: [], tags: []}}],
            series: [{guid: makeid(), exercise: {guid: makeid(), type: 'duration', tutorials: [], tags: []}}],
            loop: false
        }]
    }
}


