import config from '../App.config';
import {collection, deleteDoc, doc, getDoc, getDocs, getFirestore, onSnapshot, setDoc,} from '@firebase/firestore';
import {query} from "firebase/firestore";
import * as yup from 'yup';
import {getMandatoryUiLangProp2, makeid} from './Firebase'
import {getAndMergeExerciseDetails, loadRequiredAssetsForExercise} from './Exercises';
import {store} from "../redux/Store";
import {setRoutineListenerInitialised, setRoutines} from "../redux/RoutinesReducer";
import {getMyAsset} from "./Assets";

export const startRoutinesListener = (uid, setUnsubscribe) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myroutinespreview`;
    const ref = collection(getFirestore(), path);

    const q = query(ref);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const routines = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();

            let parsedRoutine = {
                id: doc.id || '',
                name: data.name || '',
                tagIds: data.tagIds || [],
                type: data.type || ''
            };

            if (data.i18n && data.i18n.name) {
                parsedRoutine.i18n = {name: data.i18n.name};
            }

            routines.push(parsedRoutine);
        });

        routines.sort((a, b) => {
            const nameA = getMandatoryUiLangProp2(a, 'name');
            const nameB = getMandatoryUiLangProp2(b, 'name');
            return nameA.localeCompare(nameB);
        });

        setUnsubscribe("ROUTINES_UNSUB_FUN", unsubscribe);
        store.dispatch(setRoutines(routines));
        store.dispatch(setRoutineListenerInitialised(true));
    });
};

export const getMyRoutine = async (uid, id, tagsSelector) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myroutines/${id}`;
    const ref = doc(getFirestore(), path)
    const snap = await getDoc(ref);
    if (snap.data()) {
        const routineData = snap.data();
        // getting whole tags for local purposes
        const fullTags = tagsSelector.tags.filter(tag => routineData.tagIds.includes(tag.id));
        if (['seriesv2'].includes(routineData.type)) {
            routineData.series.forEach(serie => {
                serie.exercise.tags = tagsSelector.tags.filter(tag => serie.exercise.tagIds.includes(tag.id));
            })
        }
        if(['circuitv2'].includes(routineData.type)){
            routineData.exercises.forEach(exercise => {
                exercise.tags = tagsSelector.tags.filter(tag => exercise.tagIds.includes(tag.id));
            })
        }
        if(['tabatav2'].includes(routineData.type)){
            routineData.rounds.forEach(round => {
                round.exercise.tags = tagsSelector.tags.filter(tag => round.exercise.tagIds.includes(tag.id));
            })
        }
        if(['pyramidv2'].includes(routineData.type)){
            routineData.exercise.tags = tagsSelector.tags.filter(tag => routineData.exercise.tagIds.includes(tag.id));
        }
        return {...routineData, id: snap.id, tags: fullTags};
    } else {
        throw new Error("Entity not found");
    }
}

// should not be used // to delete?
export const getMyRoutines = async (uid) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myroutines`;
    const ref = collection(getFirestore(), path)
    const q = await query(ref);
    const querySnapshot = await getDocs(q);
    const ret = querySnapshot.docs.map(doc => {
        return {...doc.data(), id: doc.id}
    })
    return ret
}

export const putMyRoutine = async (uid, data, newid) => {
    data.id = newid ? makeid() : (data.id || makeid());

    cleanRoutine(data);

    // tagIds for preview are okay, they don't need full tags at this point
    const previewData = {
        name: data.name,
        tagIds: data.tagIds,
        type: data.type
    };

    if (data.i18n && data.i18n.name) {
        previewData.i18n = {name: data.i18n.name};
    }

    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myroutines/${data.id}`
    const ref = doc(getFirestore(), path);

    const routinePreviewPath = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myroutinespreview/${data.id}`
    const routinePreviewRef = doc(getFirestore(), routinePreviewPath);

    await overwriteRoutineWithFullExercises(uid, data);

    setDoc(ref, data, {merge: true});
    setDoc(routinePreviewRef, previewData, {merge: true});
}

export const removeMyRoutine = async (uid, id) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myroutines/${id}`
    const ref = doc(getFirestore(), path)

    const routinePreviewPath = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myroutinespreview/${id}`
    const routinePreviewRef = doc(getFirestore(), routinePreviewPath)

    console.debug(`removeMyRoutine: ${id}`)
    deleteDoc(ref)
    deleteDoc(routinePreviewRef)
}

// should not be used // to delete?
// export const getMyRoutinesCombo = async (uid) => {
//     const ret = {}
//     const r = await getMyRoutines(uid);
//     r.forEach((item) => {
//         ret[item.name] = item
//     })
//     return ret
//
// }

export const getRoutineTypes = () => {
    return ['seriesv2', 'circuitv2', 'tabatav2', 'pyramidv2', 'wworkoutv2', 'amrapv2', 'emomv2', 'punisher'];
}


export const emptyRoutine = () => {
    return {
        guid: makeid(),
        type: 'seriesv2',
        tagIds: [],
        exercises: [{guid: makeid(), type: 'duration', tutorials: [], tags: []}],
        exercise: {guid: makeid(), type: 'duration', tutorials: [], tags: []},
        punishment: [{guid: makeid(), type: 'duration', tutorials: [], tags: []}],
        challenge: {guid: makeid(), type: 'duration', tutorials: [], tags: []},
        round: [{guid: makeid(), type: 'duration', tags: []}],
        rounds: [{guid: makeid(), exercise: {guid: makeid(), type: 'duration', tutorials: [], tags: []}}],
        series: [{guid: makeid(), exercise: {guid: makeid(), type: 'duration', tutorials: [], tags: []}}],
        loop: false,
        // local purposes only
        tags: []
    }
}


export const getExercisesSchema = (t) => {
    return yup.array().when(
        'type',
        (type, schema) => {
            switch (type) {
                case 'seriesv2':
                    schema.object().shape(
                        {
                            name: yup.string().required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')),
                            type: yup.string().required(t('Pole jest wymagane')),
                            load: yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(1000),
                            reps: yup.number(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(0, t('Minimum 0')).max(1000),
                        }
                    )
                    break;
                case 'circuitv2':
                    break;
                case 'tabatav2':
                    break;
                case 'pyramidv2':
                    break;
                case 'wworkoutv2':
                    break;
                case 'amrapv2':
                    break;
                case 'emomv2':
                    break;
                case 'punisher':
                    break;
            }
            return schema
        }
    )
}

export async function overwriteAllRoutinesWithFullExercises(uid, workoutData) {
    for (const routine of workoutData.routines) {
        await overwriteRoutineWithFullExercises(uid, routine);
    }
}

export async function overwriteRoutineWithFullExercises(uid, routine) {
    switch (routine.type) {
        case 'seriesv2':
            const seriesExercisesPromises = routine.series.map(async (seriesItem) => {
                if (!seriesItem.exercise || !seriesItem.exercise.id) {
                    return null;
                }
                await loadRequiredAssetsForExercise(uid, seriesItem.exercise);
                if (seriesItem.exercise.loadRequired) {
                    const fullExercise = await getAndMergeExerciseDetails(uid, seriesItem.exercise.id, seriesItem.exercise);
                    return {...seriesItem, exercise: {...fullExercise}};
                } else {
                    delete seriesItem.exercise.loadRequired;
                    return seriesItem;
                }
            });

            const validSeriesItems = (await Promise.all(seriesExercisesPromises)).filter(item => item !== null);
            routine.series = validSeriesItems;

            break;
        case 'circuitv2':
            const circuitExercisesPromises = routine.exercises.map(async (exercise) => {
                if (!exercise.id) {
                    return null;
                }
                await loadRequiredAssetsForExercise(uid, exercise);
                if (exercise.loadRequired) {
                    const fullExercise = await getAndMergeExerciseDetails(uid, exercise.id, exercise);
                    return {...fullExercise};
                } else {
                    delete exercise.loadRequired;
                    return exercise;
                }
            });

            const validExercises = (await Promise.all(circuitExercisesPromises)).filter(ex => ex !== null);
            routine.exercises = validExercises;

            break;
        case 'tabatav2':
            const tabataExercisesPromises = routine.rounds.map(async (tabataRound) => {
                if (!tabataRound.exercise || !tabataRound.exercise.id) {
                    return null;
                }
                await loadRequiredAssetsForExercise(uid, tabataRound.exercise);
                if (tabataRound.exercise.loadRequired) {
                    const fullExercise = await getAndMergeExerciseDetails(uid, tabataRound.exercise.id, tabataRound.exercise);
                    return {...tabataRound, exercise: {...fullExercise}};
                } else {
                    delete tabataRound.exercise.loadRequired;
                    return tabataRound;
                }
            });

            const validTabataItems = (await Promise.all(tabataExercisesPromises)).filter(item => item !== null);
            routine.rounds = validTabataItems;

            break;
        case 'pyramidv2':
        case 'wworkoutv2':
            await loadRequiredAssetsForExercise(uid, routine.exercise);
            if (routine.exercise && routine.exercise.id && routine.exercise.loadRequired) {
                const fullExercise = await getAndMergeExerciseDetails(uid, routine.exercise.id, routine.exercise);
                routine.exercise = {...fullExercise};
            }
            delete routine.exercise.loadRequired;
            break;
        case 'amrapv2':
        case 'emomv2':
            const routineExercisesPromises = routine.round.map(async (exercise) => {
                if (exercise.id && exercise.loadRequired) {
                    const fullExercise = await getAndMergeExerciseDetails(uid, exercise.id, exercise);
                    return {...fullExercise};
                } else {
                    delete exercise.loadRequired;
                    return exercise;
                }
            });
            const validRoutineRound = (await Promise.all(routineExercisesPromises)).filter(ex => ex !== null);
            routine.round = validRoutineRound;

            break;
        case 'punisher':
            if (routine.challenge && routine.challenge.id && routine.challenge.loadRequired) {
                routine.challenge = await getAndMergeExerciseDetails(uid, routine.challenge.id, routine.challenge);
            }
            delete routine.challenge.loadRequired;
            const punishmentExercisesPromises = routine.punishment.map(async (exercise) => {
                if (exercise.id && exercise.loadRequired) {
                    const updatedExercise = await getAndMergeExerciseDetails(uid, exercise.id, exercise);
                    return {...updatedExercise, loadRequired: false};
                }
                delete exercise.loadRequired;
                return exercise;
            });

            const validPunishmentExercises = (await Promise.all(punishmentExercisesPromises)).filter(ex => ex !== null);
            routine.punishment = validPunishmentExercises;

            break;
    }
}

export const cleanRoutine = (r) => {
    r.tagIds = r.tags ? r.tags.map(tag => tag.id) : [];

    delete r.guid;
    r.exercises?.forEach(e => delete e.guid);
    r.punishment?.forEach(e => delete e.guid);
    if (r.challenge) delete r.challenge.guid
    r.round?.forEach(e => delete e.guid);
    r.rounds?.forEach(e => delete e.guid);
    r.series?.forEach(e => delete e.guid);
    r.tagIds = Array.isArray(r.tagIds) ? r.tagIds.filter(tag => typeof tag === 'string') : [];

    if (!['seriesv2'].includes(r.type)) {
        delete r.series;
    } else {
        if (r.series && Array.isArray(r.series)) {
            r.series.forEach(serie => {
                if (typeof serie.breakDuration !== 'number') {
                    const castedValue = Number(serie.breakDuration);
                    if (isNaN(castedValue)) {
                        serie.breakDuration = 0;
                    } else {
                        serie.breakDuration = castedValue;
                    }
                }
                serie.exercise.tagIds = serie.exercise.tags ? serie.exercise.tags.map(tag => tag.id) : [];
                delete serie.exercise.tags;
            });
        }
    }

    if (!['circuitv2'].includes(r.type)) {
        delete r.exercises;
        delete r.breakDuration;
        delete r.numOfRounds;
    } else {
        if (typeof r.breakDuration !== 'number') {
            const castedBreakDuration = Number(r.breakDuration);
            if (isNaN(castedBreakDuration)) {
                r.breakDuration = 0;
            } else {
                r.breakDuration = castedBreakDuration;
            }
        }

        if (typeof r.numOfRounds !== 'number') {
            const castedNumOfRounds = Number(r.numOfRounds);
            if (isNaN(castedNumOfRounds)) {
                r.numOfRounds = 0;
            } else {
                r.numOfRounds = castedNumOfRounds;
            }
        }
        r.exercises.forEach(exercise => {
            exercise.tagIds = exercise.tags ? exercise.tags.map(tag => tag.id) : [];
            delete exercise.tags;
        });
    }

    if (!['circuitv2', 'emomv2'].includes(r.type)) {
        delete r.numOfRounds;
    }

    if (!['tabatav2'].includes(r.type)) {
        delete r.rounds;
    } else {
        if (r.rounds && Array.isArray(r.rounds)) {
            r.rounds.forEach(round => {
                if (typeof round.breakDuration !== 'number') {
                    const castedValue = Number(round.breakDuration);
                    if (isNaN(castedValue)) {
                        round.breakDuration = 0;
                    } else {
                        round.breakDuration = castedValue;
                    }
                }
                round.exercise.tagIds = round.exercise.tags ? round.exercise.tags.map(tag => tag.id) : [];
                delete round.exercise.tags;
            });
        }
    }

    if (!['amrapv2'].includes(r.type)) {
        delete r.timeLimit;
    }
    if (!['amrapv2', 'emomv2'].includes(r.type)) {
        delete r.round;
    }
    if (!['emomv2'].includes(r.type)) {
        delete r.roundDuration;
    }
    if (!['pyramidv2'].includes(r.type)) {
        delete r.loop;
    } else {
        if (typeof r.startReps !== 'number') {
            const castedStartReps = Number(r.startReps);
            r.startReps = isNaN(castedStartReps) ? 1 : castedStartReps;
        }

        if (typeof r.endReps !== 'number') {
            const castedEndReps = Number(r.endReps);
            r.endReps = isNaN(castedEndReps) ? 2 : castedEndReps;
        }

        if (typeof r.progress !== 'number') {
            const castedProgress = Number(r.progress);
            r.progress = isNaN(castedProgress) ? 1 : castedProgress;
        }

        if (typeof r.loop !== 'boolean') {
            r.loop = Boolean(r.loop) ? r.loop : false;
        }
        r.exercise.tagIds = r.exercise.tags ? r.exercise.tags.map(tag => tag.id) : [];
        delete r.exercise.tags;
    }

    if (!['pyramidv2', 'wworkoutv2'].includes(r.type)) {
        delete r.startReps;
        delete r.endReps;
        delete r.progress;
        delete r.exercise
    }
    if (!['punisher'].includes(r.type)) {
        delete r.challenge;
        delete r.punishment;
    }

    // remove local property tags before saving to firebase
    delete r.tags;

    return r;
}