import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    routinesLibrary: [],
    routinesLibraryListenerInitialised: false
};

export const routinesLibrarySlice = createSlice({
    name: 'routinesLibraryListener',
    initialState,
    reducers: {
        setRoutinesLibrary: (state, action) => {
            state.routinesLibrary = action.payload
        },
        setRoutineLibraryListenerInitialised: (state, action) => {
            state.routinesLibraryListenerInitialised = action.payload
        }
    },
});

export const {setRoutinesLibrary} = routinesLibrarySlice.actions;
export const {setRoutineLibraryListenerInitialised} = routinesLibrarySlice.actions;
export const selectRoutinesLibrary = (state) => state.routinesLibrary;

export default routinesLibrarySlice.reducer;
