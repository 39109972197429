import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    plans: [],
    plansListenerInitialised: false
};

export const plansSlice = createSlice({
    name: 'plansListener',
    initialState,
    reducers: {
        setPlans: (state, action) => {
            state.plans = action.payload
        },
        setPlansListenerInitialised: (state, action) => {
            state.plansListenerInitialised = action.payload
        }
    },
});

export const {setPlans} = plansSlice.actions;
export const {setPlansListenerInitialised} = plansSlice.actions;
export const selectPlans = (state) => state.plans;

export default plansSlice.reducer;
