/* eslint-disable default-case */
import React from 'react';
import {
    Paper,
    Typography,
    Tabs,
    Tab,
} from '@mui/material';
import PageContainer from '../../components/container/PageContainer';
import Breadcrumb from '../../layouts/full-layout/breadcrumb/Breadcrumb';
import ProtectedRoute from '../../routes/ProtectedRoute';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ErrorDialog from '../../components/ErrorDialog';
import ErrorBoundaryLocal from '../../ErrorBoundaryLocal';
import { useEffect } from 'react';
import MyAccountChangePasswordTab from './MyAccountChangePasswordTab';
import MyAccountFinancialDataTab from './MyAccountFinancialDataTab';

const MyAccount = (props) => {

    const { tab, id } = useParams();
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [exception, setException] = React.useState(false);
    const [currentTab, setCurrentTab] = React.useState(0);

    const getPageTitle = () => {
        return 'Your Account';
    }
    const pageTitle = getPageTitle();

    const getTabContent = () => {
        let t;
        switch (currentTab) {
            case 0:
                t = <MyAccountFinancialDataTab mode='edit'/>;
                break;
            case 1:
                t = <MyAccountChangePasswordTab />;
                break;
        }
        return t;
    }

    // console.log('id'+id,'CurrentTab'+tab)

    const resetCurrentTab = () => {
        let t;
        if (tab === undefined) t = 0;
        switch (tab) {
            case 'profile':
                t = 0;
                break;
            case 'changepassword':
                t = 1;
                break;
        }
        setCurrentTab(t);
    }

    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
        switch(newValue) {
            case 0:
                navigate(`/account/profile`)
                break;
            case 1:
                navigate(`/account/changepassword`);
                break;
        }
    };

    useEffect(resetCurrentTab);
    useEffect(resetCurrentTab, [id, tab]);

    return (
        <ErrorBoundaryLocal>
            <ProtectedRoute allowedRoles={['admin', 'influencer']}>

                <PageContainer title={t(pageTitle)} description={t('this is Workout Edit page')}>

                    <Breadcrumb
                        title={t(getPageTitle())}
                        subtitle={'Dane klienta YourHeroes, części platformy YouHero'}
                        items={[{ to: '/', title: 'Dashboard' }, { to: '/account/profile', title: 'Your account' }]}
                    />

                    <Tabs
                        value={currentTab}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                    >
                        <Tab label="Dane rozliczeniowe" />
                        <Tab label="Zmiana hasła" />
                        <Tab label="Twoje resume" disabled />
                        <Tab label="Zgody" disabled />
                        <Tab label="Czat z YouHero" disabled />
                    </Tabs>

                    <Paper sx={{ p: 4 }}>

                        <Typography variant="h2" fontWeight={700} sx={{ my: 2 }}>
                            {getTabContent()}
                        </Typography>

                    </Paper>

                </PageContainer>

                {exception && (<ErrorDialog error={exception} onClose={() => setException(false)}></ErrorDialog>)}

            </ProtectedRoute>
        </ErrorBoundaryLocal>
    )
};

export default MyAccount;
