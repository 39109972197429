import { pl, enGB } from 'date-fns/locale';
import moment from 'moment';

export const uiLangToLocale = (uiLang) => {
    switch(uiLang) {
        case 'pl':
            return pl;
        case 'en':
        default:
            return enGB;
    }
}

export default moment;

