import { Alert, Snackbar as MuiSnackbar } from '@mui/material';
import { useState } from 'react';

export function useSnackbar() {
  const [exception, setException] = useState();
  const [success, setSuccess] = useState();

  let Snackbar;

  if (exception)
    Snackbar = (
      <MuiSnackbar
        open={!!exception}
        onClose={() => setException()}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">{exception}</Alert>
      </MuiSnackbar>
    );

  if (success)
    Snackbar = (
      <MuiSnackbar
        open={!!success}
        autoHideDuration={5000}
        onClose={() => setSuccess()}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success">{success}</Alert>
      </MuiSnackbar>
    );

  return {
    exception,
    setException,
    success,
    setSuccess,
    Snackbar,
  };
}
