import config from '../App.config';
import {doc, setDoc, getDoc, getFirestore, onSnapshot} from '@firebase/firestore';
import {store} from "../redux/Store";
import {setUser, setUserListenerInitialised} from "../redux/UserReducer";

export const startUserListener = (uid, setUnsubscribe) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/mysettings/yhrs-general`; // Assuming this is the correct path to your document
    const ref = doc(getFirestore(), path);

    const unsubscribe = onSnapshot(ref, (docSnapshot) => {
        if (docSnapshot.exists()) {
            let userDoc = docSnapshot.data();
            console.log(userDoc)

            const user = {}
            user.uid = uid;
            user.settings = {}
            user.settings.langs = userDoc?.contentLangs || ['en']
            user.settings.uiLang = userDoc?.uiLang || 'en';

            store.dispatch(setUser(user));
        } else {
            console.log("No such document!");
        }

        setUnsubscribe("USER_UNSUB_FUN", unsubscribe);
        store.dispatch(setUserListenerInitialised(true));
    });
};

export const getMySettings = async (uid, name) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/mysettings/${name}`;
    const ref = doc(getFirestore(), path);
    const snap = await getDoc(ref);
    return snap.data() || {}
}

export const putMySettings = async (uid, name, document) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/mysettings/${name}`;
    const ref = doc(getFirestore(), path);
    await setDoc(ref, document, {merge: true});
}