import config from '../App.config';
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter
} from '@firebase/firestore';
import {store} from "../redux/Store";
import {setExerciseListenerInitialised, setExercises} from "../redux/ExercisesReducer";
import {getMandatoryUiLangProp2, makeid} from "./Firebase";
import {fetchAsset, getMyAsset} from "./Assets";

export const startExercisesListener = (uid, setUnsubscribe) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myexercisespreview`;
    const ref = collection(getFirestore(), path);

    const q = query(ref);

    // data for exercise list PREVIEW - no need for the whole model here
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const exercises = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();

            let parsedExercise = {
                id: doc.id || '',
                name: data.name || '',
                tagIds: data.tagIds || [],
                type: data.type || 'duration',
                tempo: data.tempo || '2-0-2-0'
            };

            if (data.i18n && data.i18n.name) {
                parsedExercise.i18n = {name: data.i18n.name};
            }

            exercises.push(parsedExercise);
        });

        exercises.sort((a, b) => {
            const nameA = getMandatoryUiLangProp2(a, 'name');
            const nameB = getMandatoryUiLangProp2(b, 'name');
            return nameA.localeCompare(nameB);
        });

        setUnsubscribe("EXERCISES_UNSUB_FUN", unsubscribe);
        store.dispatch(setExercises(exercises));
        store.dispatch(setExerciseListenerInitialised(true));
    });
};

// ---  ------------------------------------------------------------------
export const getMyExercises = async (uid) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myexercises`;
    const ref = collection(getFirestore(), path)
    const q = await query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
        return {...doc.data(), id: doc.id}
    })
}


// ---  ------------------------------------------------------------------
export const getMyExercisesPage = async (uid, last) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myexercises`;
    const ref = collection(getFirestore(), path)
    let q;
    if (last) {
        q = await query(ref, orderBy('name'), startAfter(last), limit(20));
    } else {
        q = await query(ref, orderBy('name'), limit(20));
    }
    const querySnapshot = await getDocs(q);
    const _last = (querySnapshot.size > 0) ? querySnapshot.docs[querySnapshot.size - 1] : null
    const data = querySnapshot.docs?.map(doc => {
        return {...doc.data(), id: doc.id}
    })
    return {data, last: _last};
}


// ---  ------------------------------------------------------------------  // // TODO EXERCISE - make sure that the model is whole  ?? or since I ensure it in saving it's not required here?
export const getMyExercise = async (uid, id, tagsSelector) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myexercises/${id}`;
    const ref = doc(getFirestore(), path)
    const snap = await getDoc(ref);
    if (snap.data()) {
        const exerciseData = snap.data();
        // getting whole tags for local purposes
        const fullTags = tagsSelector.tags.filter(tag => exerciseData.tagIds.includes(tag.id));

        return { ...exerciseData, id: snap.id, tags: fullTags };
    } else {
        throw new Error("Entity not found");
    }
}
// It will replace things like description, i18n and assets when you pick an exercise from the exercise template list and not customise it
export const getAndMergeExerciseDetails = async (uid, exerciseId, originalExercise) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myexercises/${exerciseId}`;
    const ref = doc(getFirestore(), path);
    const snap = await getDoc(ref);

    // get all assets for originalExercise

    console.log("test444" + originalExercise);

    if (snap.data()) {
        return {
            ...snap.data(),
            id: snap.id,
            type: originalExercise.type,
            intensity: originalExercise.intensity || null,
            typeValue: originalExercise.typeValue || null,
            intensityValue: originalExercise.intensityValue || null,
            loadRMPerc: originalExercise.loadRMPerc || null,
            loadValue: originalExercise.loadValue || null,
            tempo: originalExercise.tempo || null
        };
    } else {
        throw new Error("Entity not found");
    }
};

// ---  ------------------------------------------------------------------
export const putMyExercise = async (uid, data, newid) => {
    data.id = newid ? makeid() : (data.id || makeid());

    data.tagIds = data.tags ? data.tags.map(tag => tag.id) : [];

    const formattedData = toJson(data);

    const previewData = {
        name: formattedData.name,
        tagIds: formattedData.tagIds
    };

    if (formattedData.i18n && formattedData.i18n.name) {
        previewData.i18n = {name: formattedData.i18n.name};
    }

    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myexercises/${formattedData.id}`
    const ref = doc(getFirestore(), path);

    const exercisePreviewPath = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myexercisespreview/${formattedData.id}`
    const exercisePreviewRef = doc(getFirestore(), exercisePreviewPath);

    if (Array.isArray(formattedData.tutorials)) {
        formattedData.tutorials = await Promise.all(formattedData.tutorials.map(async (item) => {
            if (item.id) return fetchAsset(uid, item.id);
            return null;
        })).then(results => results.filter(item => item !== null));
    }

    setDoc(ref, formattedData, {merge: true});
    setDoc(exercisePreviewRef, previewData, {merge: true});
}


// ---  ------------------------------------------------------------------
export const removeMyExercise = async (uid, id) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myexercises/${id}`
    const ref = doc(getFirestore(), path)

    const exercisePreviewPath = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myexercisespreview/${id}`
    const exercisePreviewRef = doc(getFirestore(), exercisePreviewPath)

    console.debug(`removeMyExercise: ${id}`)
    deleteDoc(ref)
    deleteDoc(exercisePreviewRef)
}


// ---  ------------------------------------------------------------------
export const getExerciseTypes = () => {
    return ['duration', 'reps'];
}

// ---  ------------------------------------------------------------------
export const emptyExercise = () => ({
    id: makeid(),
    name: '',
    description: '',
    tagIds: [],
    tutorials: [],
    type: 'duration',
    intensity: null,
    typeValue: null,
    intensityValue: null,
    loadRMPerc: null,
    loadValue: null,
    tempo: '2-0-2-0',
    i18n: null,
    // local purposes only
    tags: []
});

const toJson = (data) => {
    if (typeof data.name !== 'string') {
        throw new Error('Field "name" is required and must be a string');
    }

    if (typeof data.id !== 'string') {
        data.id = makeid();
    }

    return {
        id: data.id,
        name: data.name,
        description: typeof data.description === 'string' ? data.description : '',
        tagIds: Array.isArray(data.tagIds) ? data.tagIds.filter(tag => typeof tag === 'string') : [],
        tutorials: Array.isArray(data.tutorials) ? data.tutorials : [],
        type: typeof data.type === 'string' ? data.type : null,
        intensity: typeof data.intensity === 'string' ? data.intensity : null,
        typeValue: typeof data.typeValue === 'number' ? data.typeValue : null,
        intensityValue: typeof data.intensityValue === 'number' ? data.intensityValue : null,
        loadRMPerc: typeof data.loadRMPerc === 'number' ? data.loadRMPerc : null,
        loadValue: typeof data.loadValue === 'number' ? data.loadValue : null,
        tempo: typeof data.tempo === 'string' ? data.tempo : null,
        i18n: typeof data.i18n === 'object' ? data.i18n : null,
    };
};

export const loadRequiredAssetsForExercise = async (uid, exercise) => {
    if (!exercise || !Array.isArray(exercise.tutorials)) {
        console.error('Nieprawidłowy obiekt exercise.');
        return;
    }

    try {
        exercise.tutorials = await Promise.all(
            exercise.tutorials.map(async (tutorial) => {
                if (tutorial.loadRequired) {
                    try {
                        const newAsset = await getMyAsset(uid, tutorial.id);
                        return newAsset;
                    } catch (error) {
                        console.error(`Błąd ładowania assetu dla tutorialu ID ${tutorial.id}:`, error);
                        return tutorial;
                    }
                }
                delete tutorial.loadRequired;
                return tutorial;
            })
        );
    } catch (error) {
        console.error('Błąd podczas przetwarzania tutoriali:', error);
    }
};