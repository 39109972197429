import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    workoutsLibrary: [],
    workoutsLibraryListenerInitialised: false
};

export const workoutsLibrarySlice = createSlice({
    name: 'workoutsLibraryListener',
    initialState,
    reducers: {
        setWorkoutsLibrary: (state, action) => {
            state.workoutsLibrary = action.payload
        },
        setWorkoutsLibraryListenerInitialised: (state, action) => {
            state.workoutsLibraryListenerInitialised = action.payload
        }
    },
});

export const {setWorkoutsLibrary} = workoutsLibrarySlice.actions;
export const {setWorkoutsLibraryListenerInitialised} = workoutsLibrarySlice.actions;
export const selectWorkoutsLibrary = (state) => state.workoutsLibrary;

export default workoutsLibrarySlice.reducer;
