import config from '../App.config';
import {getFirestore, getDocs, collection, onSnapshot, doc, setDoc, deleteDoc, getDoc} from '@firebase/firestore';

import { query } from "firebase/firestore";
import {store} from "../redux/Store";
import {setTags} from "../redux/TagsReducer";
import {setTagsListenerInitialised} from "../redux/TagsReducer";
import Firebase from "./Firebase";

export const startTagsListener = (uid, setUnsubscribe) => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/core/tags/`;
    const ref = collection(getFirestore(), path);

    const q = query(ref);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const tags = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();

            let parsedTag = {
                id: data.id || '',
                name: data.name || '',
                order: data.order,
                category: data.category || '',
                ...(data.i18n && data.i18n.name && { i18n: { name: data.i18n.name } }),
            };

            tags.push(parsedTag);
        });

        tags.sort((a, b) => a.order - b.order);

        setUnsubscribe("TAGS_UNSUB_FUN", unsubscribe);
        store.dispatch(setTags(tags));
        store.dispatch(setTagsListenerInitialised(true));
    });
};

// ---  ------------------------------------------------------------------
// SHOULD NOT be used // changed to tags snapshot listener approach (tagsSelector)
export const getTags = async () => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/core/tags/`;
    const ref = collection(getFirestore(),path)
    const q = await query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
        return {...doc.data(), id: doc.id}
    })
}

export const getTag = async (uid, id) => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/core/tags/${id}`;
    const ref = doc(getFirestore(), path)
    const snap = await getDoc(ref);
    if (snap.data()) {
        return {...snap.data(), id: snap.id};
    } else {
        throw new Error("Entity not found");
    }
}


export const putTag = async (uid, data) => {

    data.id = `${data.category.toLowerCase()}-${data.name
        .replace(/\s+/g, '-')
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase()}`;

    const path = `${config.FIRESTORE_ROOT}/contentLibrary/core/tags/${data.id}`;
    const ref = doc(getFirestore(), path);

    setDoc(ref, data, {merge: true});
}

export const removeTag = async (id) => {
    const path = `${config.FIRESTORE_ROOT}/contentLibrary/core/tags/${id}`
    const ref = doc(getFirestore(), path)

    console.debug(`removeTag: ${id}`)
    deleteDoc(ref)
}

// ---  ------------------------------------------------------------------
export const getTagCategories = () => {   // TODO!! add ui category group and link them
    return ['activitytype', 'author', 'bodypart', 'duration', 'expLevel','sports','routinetype','equipment', 'muscle'];
}

// ---  ------------------------------------------------------------------
export const emptyTag = {
    name: '',
    category: '',
    description: '',
    id: '',
}

export const concatTags = (tags, tagsTranslations) => {
    let lang = Firebase.user?.settings?.uiLang || 'en';
    if (tagsTranslations && Object.keys(tagsTranslations).length > 0) {
        return tags?.map(el => {
            const translationObject = tagsTranslations.find(t => t.id === el.id);
            const translation = translationObject?.i18n?.['name']?.[lang] || el.id?.split('-')[1];
            return `#${translation} `;
        });
    } else {
        return tags?.map(el => `#${el.id.split('-')[1]} `);
    }
}

// version for newer tags model List<String> (tagIds)
export const concatTagsList = (tagIds, tagsTranslations) => {

    let lang = Firebase.user?.settings?.uiLang || 'en';
    if (tagsTranslations && Object.keys(tagsTranslations).length > 0) {
        return tagIds?.map(el => {
            const translationObject = tagsTranslations.find(t => t.id === el);
            const translation = translationObject?.i18n?.['name']?.[lang] || el?.split('-')[1];
            return `#${translation} `;
        });
    } else {
        return tagIds?.map(el => `#${el.split('-')[1]} `);
    }
}

export const translateTagName = (tagId, tagsTranslations) => {
    const lang = Firebase.user?.settings?.uiLang || 'en';

    if (tagsTranslations && tagsTranslations.length > 0) {
        const translationObject = tagsTranslations.find(t => t.id === tagId);
        const translation = translationObject?.i18n?.['name']?.[lang];
        return `#${translation || tagId.split('-')[1]}`;
    } else {
        return `#${tagId.split('-')[1]}`;
    }
};


