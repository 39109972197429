import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { selectUser } from "../redux/UserReducer";
import Spinner from "../views/spinner/Spinner";
import {
  getAuth
} from '@firebase/auth';


const ProtectedRoute = ({
    allowedRoles,
    children,
  }) => {
    
 const [loggedIn,setLoggedIn] = useState()
 const user = useSelector(selectUser);

  useEffect(() => {
      getAuth().onAuthStateChanged(async u => {
          setLoggedIn(!!u)
      })
  }, []);

    if (loggedIn === undefined) {
      return (<Spinner/>)
    }

    if (loggedIn === false) {
      //return "GO TO LOGIN SCREEN"
      return (<Navigate to={'/auth/login'} />);
    }

    if (!user?.meta) {
      return (<Spinner/>)
    }

    let hasAccess = false;
    [...allowedRoles].forEach(r => {
        hasAccess = hasAccess || user?.meta?.roles?.indexOf(r) > -1
    });
    if (!hasAccess) {
      //return "YOU DON'T HAVE ACCESS TO THIS SCREEN"
      return (<Navigate to={'/auth/400'} />);
    }

    return children ? children : <Outlet />;
  
  };

  export default ProtectedRoute;
