import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    exercisesLibrary: [],
    exercisesLibraryListenerInitialised: false
};

export const exercisesLibrarySlice = createSlice({
    name: 'exercisesLibraryListener',
    initialState,
    reducers: {
        setExercisesLibrary: (state, action) => {
            state.exercisesLibrary = action.payload
        },
        setExerciseLibraryListenerInitialised: (state, action) => {
            state.exercisesLibraryListenerInitialised = action.payload
        }
    },
});

export const {setExercisesLibrary} = exercisesLibrarySlice.actions;
export const {setExerciseLibraryListenerInitialised} = exercisesLibrarySlice.actions;
export const selectExercisesLibrary = (state) => state.exercisesLibrary;

export default exercisesLibrarySlice.reducer;
