import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    assetsLibrary: [],
    assetsLibraryListenerInitialised: false
};

export const assetsLibrarySlice = createSlice({
    name: 'assetsLibraryListener',
    initialState,
    reducers: {
        setAssetsLibrary: (state, action) => {
            state.assetsLibrary = action.payload
        },
        setAssetsLibraryListenerInitialised: (state, action) => {
            state.assetsLibraryListenerInitialised = action.payload
        }
    },
});

export const {setAssetsLibrary} = assetsLibrarySlice.actions;
export const {setAssetsLibraryListenerInitialised} = assetsLibrarySlice.actions;
export const selectAssetsLibrary = (state) => state.assetsLibrary;

export default assetsLibrarySlice.reducer;
