import { Alert, Snackbar as MuiSnackbar } from '@mui/material';
import React, { useState } from 'react';

const ErrorDialog = (props) => {

  const [exception, setException] = useState();

  React.useEffect(() => {
      if (!!props.error) {
        setException(props.error)
      }
  }, [props]);

  return <MuiSnackbar
            open={!!exception}
            onClose={() => setException()}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert severity="error">{exception}</Alert>
          </MuiSnackbar>
};

export default ErrorDialog;
