import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    plansLibrary: [],
    plansLibraryListenerInitialised: false
};

export const plansLibrarySlice = createSlice({
    name: 'plansLibraryListener',
    initialState,
    reducers: {
        setPlansLibrary: (state, action) => {
            state.plansLibrary = action.payload
        },
        setPlansLibraryListenerInitialised: (state, action) => {
            state.plansLibraryListenerInitialised = action.payload
        }
    },
});

export const {setPlansLibrary} = plansLibrarySlice.actions;
export const {setPlansLibraryListenerInitialised} = plansLibrarySlice.actions;
export const selectPlansLibrary = (state) => state.plansLibrary;

export default plansLibrarySlice.reducer;
