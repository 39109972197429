import config from '../App.config';
import {
    getFirestore,
    getDocs,
    collection,
    where,
    orderBy,
    startAfter,
    limit,
    doc,
    updateDoc,
    setDoc,
    getDoc,
    deleteDoc,
    onSnapshot
} from '@firebase/firestore';
import { query } from "firebase/firestore";
import * as yup from 'yup';
import { getDownloadURL, getStorage, ref } from '@firebase/storage';
import Firebase from './Firebase';
import {getMandatoryUiLangProp2, makeid} from "./Firebase";
import {store} from "../redux/Store";
import {setAssetsListenerInitialised, setAssets} from "../redux/AssetsReducer";

export const startAssetsListener = (uid, setUnsubscribe) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myassetspreview`;
    const ref = collection(getFirestore(), path);

    const q = query(ref);

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const assets = [];
        querySnapshot.forEach((doc) => {
            let data = doc.data();

            let parsedAsset = {
                id: doc.id || '',
                name: data.name || '',
                type: data.type || 'text'
            };

            if (data.i18n && data.i18n.name) {
                parsedAsset.i18n = {name: data.i18n.name};
            }

            if (data.downloadUrl) {
                parsedAsset.downloadUrl = data.downloadUrl;
            }

            assets.push(parsedAsset);
        });

        assets.sort((a, b) => {
            const nameA = getMandatoryUiLangProp2(a, 'name');
            const nameB = getMandatoryUiLangProp2(b, 'name');
            return nameA.localeCompare(nameB);
        });

        setUnsubscribe("ASSETS_UNSUB_FUN", unsubscribe);
        store.dispatch(setAssets(assets));
        store.dispatch(setAssetsListenerInitialised(true));
    });
};

// ---  ------------------------------------------------------------------
export const getMyAssetsPage = async (uid, last) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myassets`;
  const ref = collection(getFirestore(),path)
  let q;
  if (last) {
    q = await query(ref, orderBy('name'), startAfter(last), limit(20));
  } else {
    q = await query(ref, orderBy('name'), limit(20));
  }
  const querySnapshot = await getDocs(q);
  const _last = (querySnapshot.size > 0) ? querySnapshot.docs[querySnapshot.size - 1] : null
  const data = querySnapshot.docs?.map(doc => {
      return {...doc.data(), id: doc.id}
  })
  return {data, last: _last};
}

// ---  ------------------------------------------------------------------
export const getMyAsset = async (uid, id) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myassets/${id}`;
  const ref = doc(getFirestore(),path)
  const snap = await getDoc(ref);
  if (snap.data()) {
      return {...emptyAsset(), ...snap.data(), id: snap.id};
  } else {
      throw new Error("Entity not found");
  }
}

// ---  ------------------------------------------------------------------
export const getMyHeros = async (uid) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myassets`;
    const _ref = collection(getFirestore(), path)
    const q = await query(_ref, where('type', 'in', ['storageImage', 'ytvideo']));
    const querySnapshot = await getDocs(q);
    const ret = querySnapshot.docs.map(doc => {
      return { ...doc.data(), id: doc.id }
    })
    const storage = getStorage(Firebase.app)
    ret.forEach(async asset => {
      const storageRef = ref(storage, asset.heroImagePath)
      asset._downloadUrl = await getDownloadURL(storageRef);
    })
    return ret
}

// ---  ------------------------------------------------------------------
export const getMyTutorials = async (uid) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myassets`;
    const ref = collection(getFirestore(), path)
    const q = await query(ref, where('type', 'in', ['storageImage', 'ytvideo', 'text']));
    const querySnapshot = await getDocs(q);
    const ret = querySnapshot.docs.map(doc => {
      return { ...doc.data(), id: doc.id }
    })
    return ret
}

// ---  ------------------------------------------------------------------
export const getMyExtras = async (uid) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myassets`;
    const ref = collection(getFirestore(), path)
    const q = await query(ref, where('type', 'in', ['link', 'text']));
    const querySnapshot = await getDocs(q);
    const ret = querySnapshot.docs.map(doc => {
      return { ...doc.data(), id: doc.id }
    })
    return ret
}


// ---  ------------------------------------------------------------------
export const putMyAsset = async (uid, data, newid) => {
    const previewData = {
        name: data.name,
        type: data.type ? data.type : ''
    };

    if (data.i18n && data.i18n.name) {
        previewData.i18n = {name: data.i18n.name};
    }

    if (data.heroImagePath) {
        previewData.heroImagePath = data.heroImagePath;
    }

    if (data.heroImagePath) {
        previewData.heroImagePath = data.heroImagePath;
    }

    if (data.startTime) {
        const parsedStartTime = parseInt(data.startTime);
        data.startTime = Number.isInteger(parsedStartTime) ? parsedStartTime : 0;
    }

    if (data.downloadUrl) {
        previewData.downloadUrl = data.downloadUrl;
    }

    if(newid)
      {
          data.id = makeid();
      }
      else
      {
          data.id = data.id || makeid();
      }

    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myassets/${data.id}`
    const ref = doc(getFirestore(), path);

    const assetsPreviewPath = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myassetspreview/${data.id}`
    const assetsPreviewRef = doc(getFirestore(), assetsPreviewPath);

    setDoc(ref, data, {merge: true});
    setDoc(assetsPreviewRef, previewData, {merge: true});
}


// ---  ------------------------------------------------------------------
export const removeMyAsset = async (uid, id) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myassets/${id}`
  const ref = doc(getFirestore(),path)
  console.debug(`removeMyExercise: ${id}`)
  await deleteDoc(ref)
}

// ---------------------------------------------------------------------
export const getAssetTypes = () => {
  return ['text', 'ytvideo', 'storageImage', 'link'];
}

// ---------------------------------------------------------------------
export const emptyAsset = () => {
  return {
    name: '',
    description: '',
    type: 'text',
    startTime: 0,
    ytId: '',
    link: '',
  }
}

export const translateAssetType = (assetType, t) => {
    switch (assetType) {
        case 'text':
            return t('asset_type_text');
        case 'link':
            return t('asset_type_link');
        case 'ytvideo':
            return t('asset_type_yt_video');
        case 'storageImage':
            return t('asset_type_storage_image');
        default:
            return assetType;
    }
};


// ---------------------------------------------------------------------
export const assetSchema = (t) => yup
  .object()
  .shape({
    name: 
      yup.string().required(t('Field is mandatory')).min(3, t('Min 3 chars')).max(255, t('Max 255 chars')),
    description: 
      yup.string().required(t('Field is mandatory')).min(3, t('Min 3 chars')).max(1000, t('Max 1000 chars')),
    type: yup.string(t('Field is mandatory')).required(t('Field is mandatory')),
    ytId: yup.string(t('Field is mandatory')).when('type', ([type], schema) => {
      return (type==='ytvideo') ? yup.string(t('Field is mandatory')).required(t('Field is mandatory')).min(3, t('Min 3 chars')).max(255, t('Max 255 chars')) : schema
    }),
    startTime: yup.number(t('Field is mandatory')).when('type', ([type], schema) => {
      return (type==='ytvideo') ? yup.number(t('Field is mandatory')).required(t('Field is mandatory')).min(0, t('Minimum 0')).max(100000, t('Maksimum 100000')) : schema
    }),
    heroImagePath: yup.string(t('Field is mandatory')).when('type', ([type], schema) => {
      return (type==='ytvideo' || type==='storageImage') ? yup.string(t('Field is mandatory')).required(t('Field is mandatory')) : schema
    }),
    browserLink: yup.string(t('Field is mandatory')).when('type', ([type], schema) => {
      return (type==='link') ? yup.string().url(t('Valid URL address required')).required(t('Field is mandatory')) : schema
    }),
  })
  .required();

// ---------------------------------------------------------------------
export const getUserStoragePath = (uid) => {
  return `/usersdata/${uid}/`
}

export async function fetchAsset(uid, assetId) {
    const assetPath = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myassets/${assetId}`;
    const assetRef = doc(getFirestore(), assetPath);
    const assetSnapshot = await getDoc(assetRef);
    return assetSnapshot.exists() ? assetSnapshot.data() : null;
}