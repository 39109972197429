import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    exercises: [],
    exercisesListenerInitialised: false
};

export const exercisesSlice = createSlice({
    name: 'exercisesListener',
    initialState,
    reducers: {
        setExercises: (state, action) => {
            state.exercises = action.payload
        },
        setExerciseListenerInitialised: (state, action) => {
            state.exercisesListenerInitialised = action.payload
        }
    },
});

export const {setExercises} = exercisesSlice.actions;
export const {setExerciseListenerInitialised} = exercisesSlice.actions;
export const selectExercises = (state) => state.exercises;

export default exercisesSlice.reducer;
