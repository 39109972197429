import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full-layout/loadable/Loadable';
import MyAccount from '../views/myaccount/MyAccount';
import { element } from 'prop-types';


/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full-layout/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank-layout/BlankLayout')));
/* ***End Layouts**** */

const Error = Loadable(lazy(() => import('../views/authentication/Error')));
const Exception = Loadable(lazy(() => import('../views/authentication/Exception')));
const Login = Loadable(lazy(() => import('../views/authentication/Login')));
const Register = Loadable(lazy(() => import('../views/authentication/Register')));
const RegisterThankYou = Loadable(lazy(() => import('../views/authentication/RegisterThankYou')));
const RequestPasswordChange = Loadable(lazy(() => import('../views/authentication/RequestPasswordChange')));

/* ****Pages***** */
const Dashboard = Loadable(lazy(() => import('../views/dashboards/Dashboard')));
// const Dashboard2 = Loadable(lazy(() => import('../views/dashboards/Dashboard2')));
// const Dashboard3 = Loadable(lazy(() => import('../views/dashboards/Dashboard3')));

/* ****Apps***** */
// const Chats = Loadable(lazy(() => import('../views/apps/chats/Chats')));
// const Notes = Loadable(lazy(() => import('../views/apps/notes/Notes')));
// const Email = Loadable(lazy(() => import('../views/apps/email/Email')));
// const Shop = Loadable(lazy(() => import('../views/apps/shop/Shop')));
// const QuillEditor = Loadable(lazy(() => import('../views/quill-editor/QuillEditor')));
// const Treeview = Loadable(lazy(() => import('../views/treeview/Treeview')));
// const Pricing = Loadable(lazy(() => import('../views/pricing/Pricing')));
// const CustomTimeline = Loadable(lazy(() => import('../views/timeline/CustomTimeline')));
// const CustomTypography = Loadable(lazy(() => import('../views/typography/CustomTypography')));
// const Calendar = Loadable(lazy(() => import('../views/apps/calendar/ACalendar')));
// const CustomerEdit = Loadable(lazy(() => import('../views/apps/customers/CustomerEdit')));
const ExerciseList = Loadable(lazy(() => import('../views/exercise/ExerciseList')));
const ExerciseEdit = Loadable(lazy(() => import('../views/exercise/ExerciseEdit')));
const ExerciseListLibrary = Loadable(lazy(() => import('../views/exercise-yhlib/ExerciseListLibrary')));
const ExerciseViewLibrary = Loadable(lazy(() => import('../views/exercise-yhlib/ExerciseViewLibrary')));

const WorkoutEdit = Loadable(lazy(() => import('../views/workout/WorkoutEdit')));
const WorkoutLists = Loadable(lazy(() => import('../views/workout/WorkoutList')));
const WorkoutListsLibrary = Loadable(lazy(() => import('../views/workout-yhlib/WorkoutListLibrary')));
const WorkoutViewLibrary = Loadable(lazy(() => import('../views/workout-yhlib/WorkoutViewLibrary')));

const PlanLists = Loadable(lazy(() => import('../views/plan/PlanList')));
const PlanEdit = Loadable(lazy(() => import('../views/plan/PlanEdit')));
const PlanListLibrary = Loadable(lazy(() => import('../views/plan-yhlib/PlanListLibrary')));

const AssetList = Loadable(lazy(() => import('../views/asset/AssetList')));
const AssetEdit = Loadable(lazy(() => import('../views/asset/AssetEdit')));
const AssetListLibrary = Loadable(lazy(() => import('../views/asset-yhlib/AssetListLibrary')));
const AssetViewLibrary = Loadable(lazy(() => import('../views/asset-yhlib/AssetViewLibrary')));

const TagList = Loadable(lazy(() => import('../views/tag/TagList')));
const TagEdit = Loadable(lazy(() => import('../views/tag/TagEdit')));

const RoutineList = Loadable(lazy(() => import('../views/routine/RoutineList')));
const RoutineEdit = Loadable(lazy(() => import('../views/routine/RoutineEdit')));
const RoutineListLibrary = Loadable(lazy(() => import('../views/routine-yhlib/RoutineListLibrary')));
const RoutineViewLibrary = Loadable(lazy(() => import('../views/routine-yhlib/RoutineViewLibrary')));

const AtheteList = Loadable(lazy(() => import('../views/athletes/AtheteList')));
const AtheteTabs = Loadable(lazy(() => import('../views/athletes/AtheteTabs')));

//const RoutineEdit = Loadable(lazy(() => import('../views/routine/RoutineEdit')));

/* ****Tables***** */
// const BasicTable = Loadable(lazy(() => import('../views/tables/BasicTable')));
// const PaginationTable = Loadable(lazy(() => import('../views/tables/PaginationTable')));
// const EnhancedTable = Loadable(lazy(() => import('../views/tables/EnhancedTable')));
// const CollapsibleTable = Loadable(lazy(() => import('../views/tables/CollapsibleTable')));
// const FixedHeaderTable = Loadable(lazy(() => import('../views/tables/FixedHeaderTable')));

// form elements
// const ExAutoComplete = Loadable(lazy(() => import('../views/form-elements/ExAutoComplete')));
// const ExButton = Loadable(lazy(() => import('../views/form-elements/ExButton')));
// const ExCheckbox = Loadable(lazy(() => import('../views/form-elements/ExCheckbox')));
// const ExDateTime = Loadable(lazy(() => import('../views/form-elements/ExDateTime')));
// const ExRadio = Loadable(lazy(() => import('../views/form-elements/ExRadio')));
// const ExSlider = Loadable(lazy(() => import('../views/form-elements/ExSlider')));
// const ExSwitch = Loadable(lazy(() => import('../views/form-elements/ExSwitch')));
// const FormWizard = Loadable(lazy(() => import('../views/form-layouts/FormWizard')));
// form layouts
// const FormLayouts = Loadable(lazy(() => import('../views/form-layouts/FormLayouts')));
// const FormCustom = Loadable(lazy(() => import('../views/form-layouts/FormCustom')));

// widgets
// const WidgetFeed = Loadable(lazy(() => import('../views/widgets/widget-feed/WidgetFeed')));
// const WidgetApps = Loadable(lazy(() => import('../views/widgets/widget-apps/WidgetApps')));

// userprofile
// const UserProfile = Loadable(lazy(() => import('../views/user-profile/UserProfile')));
// const ShopDetail = Loadable(lazy(() => import('../views/apps/shop-detail/ShopDetail')));

// chart
// const LineChart = Loadable(lazy(() => import('../views/charts/LineChart')));
// const GredientChart = Loadable(lazy(() => import('../views/charts/GredientChart')));
// const DoughnutChart = Loadable(lazy(() => import('../views/charts/DoughnutChart')));
// const AreaChart = Loadable(lazy(() => import('../views/charts/AreaChart')));
// const ColumnChart = Loadable(lazy(() => import('../views/charts/ColumnChart')));
// const CandlestickChart = Loadable(lazy(() => import('../views/charts/CandlestickChart')));
// const RadialbarChart = Loadable(lazy(() => import('../views/charts/RadialbarChart')));

// icons
// const ReactIcons = Loadable(lazy(() => import('../views/icons/ReactIcons')));

// Alert
// const ExAlert = Loadable(lazy(() => import('../views/alert/ExAlert')));

/* ****Routes***** */

const AuthRouter =   [{
  path: 'auth',
  element: <BlankLayout />,
  children: [
    { path: '404', element: <Error /> },
    { path: 'error', element: <Exception /> },
    { path: 'login', element: <Login /> },
    { path: 'requestbeta', element: <Register /> },
    { path: 'requestbeta/thankyou', element: <RegisterThankYou /> },
    { path: 'requestpasschange', element: <RequestPasswordChange /> },
    { path: '*', element: <Navigate to="/auth/404" replace /> },
  ],
}]


const Router = [
  ...AuthRouter,
  { 
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },

      { path: '/account/:tab', exact: true, element: <MyAccount /> },
      { path: '/dashboard', exact: true, element: <Dashboard /> },
      { path: '/yourathletes', exact: true, element: <AtheteList /> },
      { path: '/yourathletes/:id/objectives/:id2/activity', exact: true, element: <WorkoutEdit mode='activity' /> },
      { path: '/yourathletes/:id/:tab/:id2', exact: true, element: <AtheteTabs  /> },
      { path: '/yourathletes/:id/:tab', exact: true, element: <AtheteTabs  /> },
      { path: '/yourathletes/:id', exact: true, element: <AtheteTabs /> },

      { path: '/yourexercises/list', exact: true, element: <ExerciseList /> },
      { path: '/yourexercises/edit/:id', element: <ExerciseEdit mode='edit' /> },
      { path: '/yourexercises/add', element: <ExerciseEdit mode='add' /> },
      { path: 'libraryexercise/list', exact: true, element: <ExerciseListLibrary /> },
      { path: 'libraryexercise/view/:id', element: <ExerciseViewLibrary />},

      { path: '/yourworkouts/list', exact: true, element: <WorkoutLists /> },
      { path: '/yourworkouts/edit/:id', element: <WorkoutEdit mode='edit' /> },
      { path: '/yourworkouts/add/', element: <WorkoutEdit mode='add' /> },
      { path: '/libraryworkouts/list', element: <WorkoutListsLibrary/>},
      { path: 'libraryworkouts/view/:id', element: <WorkoutViewLibrary />},


      { path: '/yourplans/list', exact: true, element: <PlanLists /> },
      { path: '/yourplans/edit/:id', element: <PlanEdit mode='edit' /> },
      { path: '/yourplans/add/', element: <PlanEdit mode='add' /> },
      { path: '/libraryplans/list', element: <PlanListLibrary/> },

      { path: '/yourassets/list', exact: true, element: <AssetList /> },
      { path: '/yourassets/edit/:id', element: <AssetEdit mode='edit' /> },
      { path: '/yourassets/add', element: <AssetEdit mode='add' /> },
      { path: '/libraryassets/list', element: <AssetListLibrary/> },
      { path: 'libraryasset/view/:id', element: <AssetViewLibrary/> },

      { path: '/yourtags/list', exact: true, element: <TagList /> },
      { path: '/yourtags/edit/:id', element: <TagEdit mode='edit' /> },
      { path: '/yourtags/add', element: <TagEdit mode='add' /> },

      { path: '/yourroutines/list', exact: true, element: <RoutineList /> },
      { path: '/yourroutines/edit/:id', exact: true, element: <RoutineEdit mode='edit' /> },
      { path: '/yourroutines/add', exact: true, element: <RoutineEdit mode='add' /> },
      { path: '/libraryroutines/list', exact: true, element: <RoutineListLibrary /> },
      { path: '/libraryroutines/view/:id', element: <RoutineViewLibrary /> },

      // { path: '/dashboards/dashboard2', exact: true, element: <Dashboard2 /> },
      // { path: '/dashboards/dashboard3', exact: true, element: <Dashboard3 /> },
      // { path: '/chats', element: <Chats /> },
      // { path: '/notes', element: <Notes /> },
      // { path: '/email', element: <Email /> },
      // { path: '/shop/lists', element: <Shop /> },
      // { path: '/calendar', element: <Calendar /> },
      // { path: '/customers/edit', element: <CustomerEdit /> },
      // { path: '/tables/basic-table', element: <BasicTable /> },
      // { path: '/tables/pagination-table', element: <PaginationTable /> },
      // { path: '/tables/enhanced-table', element: <EnhancedTable /> },
      // { path: '/tables/collapsible-table', element: <CollapsibleTable /> },
      // { path: '/tables/fixed-header-table', element: <FixedHeaderTable /> },
      // { path: '/form-layouts/form-layouts', element: <FormLayouts /> },
      // { path: '/form-elements/autocomplete', element: <ExAutoComplete /> },
      // { path: '/form-elements/button', element: <ExButton /> },
      // { path: '/form-elements/checkbox', element: <ExCheckbox /> },
      // { path: '/form-elements/date-time', element: <ExDateTime /> },
      // { path: '/form-elements/radio', element: <ExRadio /> },
      // { path: '/form-elements/slider', element: <ExSlider /> },
      // { path: '/form-elements/switch', element: <ExSwitch /> },
      // { path: '/form-layouts/form-wizard', element: <FormWizard /> },
      // { path: '/widgets/widget-feed', element: <WidgetFeed /> },
      // { path: '/widgets/widget-apps', element: <WidgetApps /> },
      // { path: '/user-profile', element: <UserProfile /> },
      // { path: '/shop/shop-detail', element: <ShopDetail /> },
      // { path: '/charts/line-chart', element: <LineChart /> },
      // { path: '/charts/gredient-chart', element: <GredientChart /> },
      // { path: '/charts/doughnut-pie-chart', element: <DoughnutChart /> },
      // { path: '/charts/area-chart', element: <AreaChart /> },
      // { path: '/charts/column-chart', element: <ColumnChart /> },
      // { path: '/charts/candlestick-chart', element: <CandlestickChart /> },
      // { path: '/charts/radialbar-chart', element: <RadialbarChart /> },
      // { path: '/react-icons', element: <ReactIcons /> },
      // { path: '/form-layouts/form-custom', element: <FormCustom /> },
      // { path: '/quill-editor', element: <QuillEditor /> },
      // { path: '/treeview', element: <Treeview /> },
      // { path: '/pricing', element: <Pricing /> },
      // { path: '/timeline', element: <CustomTimeline /> },
      // { path: '/typography', element: <CustomTypography /> },
      // { path: '/alert', element: <ExAlert /> },
      { path: '*', element: <Navigate to="/auth/404" replace /> },
    ],
  }
];

export { Router, AuthRouter }
