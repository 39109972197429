import React from 'react';
import {
    Box,
    Button,
    Grid,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import CustomTextField from '../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/UserReducer';
import { useTranslatedData } from '../../Firebase/Firebase';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Spinner from '../spinner/Spinner';
import { getMySettings, putMySettings } from '../../Firebase/User';
import { useSnackbar } from '../../components/snackbar/Snackbar';
import CustomSelect from '../../components/forms/custom-elements/CustomSelect';

const MyAccountFinancialDataTab = (props) => {
    const { t } = useTranslation();
    const { setException, setSuccess, Snackbar } = useSnackbar();
    const [isLoading, setIsLoading] = React.useState();
    const user = useSelector(selectUser);
    // eslint-disable-next-line no-unused-vars
    const [data, setData, getMandatoryUiLangPropOrRootProp, getTranslatedProp, setTranslatedProp, setTranslatedPropOnly, setRootProp] = useTranslatedData();

    // --- validation --------------------------------------
    const schema = yup
        .object()
        .shape({
            firstName: yup
                .string()
                .required(t('Required data'))
                .min(3, t('Min 3 chars'))
                .max(255, t('Max 255 chars')),
            lastName: yup
                .string()
                .required(t('Required data'))
                .min(3, t('Min 3 chars'))
                .max(255, t('Max 255 chars')),
            companyName: yup
                .string()
                // .required(t('Required data'))
                // .min(3, t('Min 3 chars'))
                .max(255, t('Max 255 chars')),
            companyAddress1: yup
                .string()
                // .required(t('Required data'))
                // .min(3, t('Min 3 chars'))
                .max(255, t('Max 255 chars')),
            companyAddress2: yup
                .string()
                // .required(t('Required data'))
                // .min(3, t('Min 3 chars'))
                .max(255, t('Max 255 chars')),
            companyPostcode: yup
                .string()
                // .required(t('Required data'))
                // .min(3, t('Min 3 chars'))
                .max(255, t('Max 255 chars')),
            companyCity: yup
                .string()
                // .required(t('Required data'))
                // .min(3, t('Min 3 chars'))
                .max(255, t('Max 255 chars')),
            companyCountry: yup
                .string()
                // .required(t('Required data'))
                // .min(3, t('Min 3 chars'))
                .max(255, t('Max 255 chars')),
            companyVATNumber: yup
                .string()
                // .required(t('Required data'))
                // .min(3, t('Min 3 chars'))
                .max(255, t('Max 255 chars')),
        })
        .required();

    // --- form -------------------------------------------
    const {
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        values: data,
        resolver: yupResolver(schema),
    });

    const onSubmit = async () => {
        reset(data);
        await handleSubmit(onSave, (errors) => console.error(errors))();
    };

    // --- save data --------------------------------------
    const onSave = async (data) => {
        try {
            setIsLoading(true);
            await putMySettings(user.uid, 'coach-billing', data);
            setSuccess(t('status/ok'));
        } catch (e) {
            setException(t('status/error'));
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    };

    // --- fetch data -------------------------------------
    React.useEffect(() => {
        const fetchData = async () => {
            try {
                if (props.mode === 'edit') {
                    setData(await getMySettings(user.uid, 'coach-billing'));
                }
            } catch (e) {
                setException(t('status/error'));
                console.error(e);
            }
        };
        user?.uid && fetchData();
    }, [user, setData, props.mode]);

    console.log('MyAccountFinancialDataTab data:', data);

    if (!data) return <Spinner />

    return (

        <Box>
            <Typography variant="h4" fontWeight="700">
                Dane klienta YourHeroes
            </Typography>

            <Typography
                color="textSecondary"
                variant="h5"
                fontWeight="400"
                sx={{
                    mb: 4,
                }}
            >
                Dane te będą używane w komunikacji oraz ewentualnych rozliczeń pomiędzy trenerem a platformą
                YouHero
            </Typography>

            <Grid container spacing={2} display="flex" justifyContent="left">
                <Grid item xs={12} md={6}>
                    <CustomFormLabel htmlFor="firstName">{t('First name')}*</CustomFormLabel>
                    <CustomTextField
                        id="firstName"
                        name="firstName"
                        variant="filled"
                        defaultValue={data.firstName}
                        error={!!errors?.firstName}
                        fullWidth
                        size="small"
                        sx={{ mb: 2 }}
                        onBlur={(e) => {
                            setRootProp('firstName', e.target.value);
                        }}
                        helperText={errors?.firstName && errors?.firstName?.message}
                        placeholder={t('First name')}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomFormLabel htmlFor="lastName">{t('Last name')}*</CustomFormLabel>
                    <CustomTextField
                        id="lastName"
                        name="lastName"
                        variant="filled"
                        defaultValue={data.lastName}
                        error={!!errors?.lastName}
                        fullWidth
                        size="small"
                        sx={{ mb: 2 }}
                        onBlur={(e) => {
                            setRootProp('lastName', e.target.value);
                        }}
                        helperText={errors?.lastName && errors?.lastName?.message}
                        placeholder={t('Last name')}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} display="flex" justifyContent="left">
                <Grid item xs={12} md={6}>
                    <CustomFormLabel htmlFor="companyName">{t('Company name')}*</CustomFormLabel>
                    <CustomTextField
                        id="companyName"
                        name="companyName"
                        variant="filled"
                        defaultValue={data.companyName}
                        error={!!errors?.companyName}
                        fullWidth
                        size="small"
                        sx={{ mb: 2 }}
                        onBlur={(e) => {
                            setRootProp('companyName', e.target.value);
                        }}
                        helperText={errors?.companyName && errors?.companyName?.message}
                        placeholder={t('Company name')}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} display="flex" justifyContent="left">
                <Grid item xs={12} md={6}>
                    <CustomFormLabel htmlFor="companyAddress1">{t('companyAddress1')}*</CustomFormLabel>
                    <CustomTextField
                        id="companyAddress1"
                        name="companyAddress1"
                        variant="filled"
                        defaultValue={data.companyAddress1}
                        error={!!errors?.companyAddress1}
                        fullWidth
                        size="small"
                        sx={{ mb: 2 }}
                        onBlur={(e) => {
                            setRootProp('companyAddress1', e.target.value);
                        }}
                        helperText={errors?.companyAddress1 && errors?.companyAddress1?.message}
                        placeholder={t('companyAddress1')}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomFormLabel htmlFor="companyAddress2">{t('companyAddress2')}*</CustomFormLabel>
                    <CustomTextField
                        id="companyAddress2"
                        name="companyAddress2"
                        variant="filled"
                        defaultValue={data.companyAddress2}
                        error={!!errors?.companyAddress2}
                        fullWidth
                        size="small"
                        sx={{ mb: 2 }}
                        onBlur={(e) => {
                            setRootProp('companyAddress2', e.target.value);
                        }}
                        helperText={errors?.companyAddress2 && errors?.companyAddress2?.message}
                        placeholder={t('companyAddress2')}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} display="flex" justifyContent="left">
                <Grid item xs={12} md={6}>
                    <CustomFormLabel htmlFor="companyPostcode">{t('Postcode')}*</CustomFormLabel>
                    <CustomTextField
                        id="companyPostcode"
                        name="companyPostcode"
                        variant="filled"
                        defaultValue={data.companyPostcode}
                        error={!!errors?.companyPostcode}
                        fullWidth
                        size="small"
                        sx={{ mb: 2 }}
                        onBlur={(e) => {
                            setRootProp('companyPostcode', e.target.value);
                        }}
                        helperText={errors?.companyPostcode && errors?.companyPostcode?.message}
                        placeholder={t('Postcode')}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomFormLabel htmlFor="companyCity">{t('City')}*</CustomFormLabel>
                    <CustomTextField
                        id="companyCity"
                        name="companyCity"
                        variant="filled"
                        defaultValue={data.companyCity}
                        error={!!errors?.companyCity}
                        fullWidth
                        size="small"
                        sx={{ mb: 2 }}
                        onBlur={(e) => {
                            setRootProp('companyCity', e.target.value);
                        }}
                        helperText={errors?.companyCity && errors?.companyCity?.message}
                        placeholder={t('City')}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} display="flex" justifyContent="left">
                <Grid item xs={12} md={6}>
                    <CustomFormLabel htmlFor="companyCountry">{t('Country')}*</CustomFormLabel>
                    <CustomSelect
                        id="companyCountry"
                        name="companyCountry"
                        variant="filled"
                        defaultValue={data.companyCountry}
                        error={!!errors?.companyCountry}
                        fullWidth
                        size="small"
                        sx={{ mb: 2 }}
                        onChange={(e) => {
                            setRootProp('companyCountry', e.target.value);
                        }}
                        helperText={errors?.companyCountry && errors?.companyCountry?.message}
                        placeholder={t('Country')}
                    >
                        <MenuItem value={'Poland'}>{t('Poland')}</MenuItem>
                        <MenuItem value={'United Kingdom'}>{t('United Kingdom')}</MenuItem>
                    </CustomSelect>
                </Grid>
            </Grid>

            <Grid container spacing={2} display="flex" justifyContent="left">
                <Grid item xs={12} md={6}>
                    <CustomFormLabel htmlFor="companyVATNumber">{t('companyVATNumber')}*</CustomFormLabel>
                    <CustomTextField
                        id="companyVATNumber"
                        name="companyVATNumber"
                        variant="filled"
                        defaultValue={data.companyVATNumber}
                        error={!!errors?.companyVATNumber}
                        fullWidth
                        size="small"
                        sx={{ mb: 2 }}
                        onBlur={(e) => {
                            setRootProp('companyVATNumber', e.target.value);
                        }}
                        helperText={errors?.companyVATNumber && errors?.companyVATNumber?.message}
                        placeholder={t('companyVATNumber')}
                    />
                </Grid>
            </Grid>

            {Snackbar}

            <Grid container spacing={2} display="flex" justifyContent="left">
                <Grid item xs={12} md={12} textAlign="right">
                    <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        component={Button}
                        onClick={onSubmit}
                        disabled={isLoading}
                        sx={{
                            pt: '10px',
                            pb: '10px',
                            mt: 4,
                        }}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Box>

    );

};

export default MyAccountFinancialDataTab;

