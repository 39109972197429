import React, { useState } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import CustomTextField from '../../components/forms/custom-elements/CustomTextField';
import CustomFormLabel from '../../components/forms/custom-elements/CustomFormLabel';
import { changePassword } from '../../Firebase/Firebase';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useSnackbar } from '../../components/snackbar/Snackbar';

const MyAccountChangePasswordTab = (props) => {


  const { t } = useTranslation();
  const [data, setData] = useState({})
  const {setSuccess, setException, Snackbar} = useSnackbar();
  const [isLoading, setIsLoading] = React.useState();

  // --- form validation schema -------------------------------------------
  const schema = yup
    .object()
    .shape({
      oldPassword:
        yup.string().required(t('Required data')),
      password:
        yup.string().required(t('Required data')).min(3, t('Min 3 chars')).max(255, t('Max 255 chars'))
          .matches(/[0-9]/, 'Password requires a number, a lowercase letter and a uppercase letter')
          .matches(/[a-z]/, 'Password requires a number, a lowercase letter and a uppercase letter')
          .matches(/[A-Z]/, 'Password requires a number, a lowercase letter and a uppercase letter'),
      password2:
        yup.string().required(t('Required data')).oneOf([yup.ref('password'), null], 'Passwords must match'),
    })
    .required();

  const { handleSubmit, formState: { errors } } = useForm({
    values: data,
    resolver: yupResolver(schema),
  })

  const onSubmit = async (e) => {
    await handleSubmit(
      async () => {
        try {
          setIsLoading(true);
          await changePassword(data.oldPassword, data.password);
          setData({oldPassword: '', password: '', password2: ''});
          setSuccess(t('status/ok'));
        } catch (e) {
          setException(t('status/error'));
          console.error(e);
        } finally {
          setIsLoading(false);
        }
      }
    )() 
  }

  return (

    <Box>
      <Grid container spacing={0} display="flex" justifyContent="left">
        <Grid item xs={12} lg={6} xl={6}>

          <Typography variant="h4" fontWeight="700">
            Change password
          </Typography>

          <Typography
            color="textSecondary"
            variant="h5"
            fontWeight="400"
            sx={{
              mb: 4,
            }}
          >
            Please enter new password
          </Typography>

          <Box>
            <CustomFormLabel htmlFor="oldPassword">{t('Old password')}*</CustomFormLabel>
            <CustomTextField
              name="oldPassword"
              variant="filled"
              fullWidth
              value={data.oldPassword}
              onChange={(e) => setData({ ...data, oldPassword: e.target.value })}
              error={!!errors?.oldPassword}
              helperText={errors?.oldPassword && errors?.oldPassword?.message}
              placeholder={t('Old password')}
            />
            <CustomFormLabel htmlFor="password">{t('New password')}*</CustomFormLabel>
            <CustomTextField
              name="password"
              variant="filled"
              fullWidth
              value={data.password}
              onChange={(e) => setData({ ...data, password: e.target.value })}
              error={!!errors?.password}
              helperText={errors?.password && errors?.password?.message}
              placeholder={t('New password')}
            />
            <CustomFormLabel htmlFor="password2">{t('Repeat new password')}*</CustomFormLabel>
            <CustomTextField
              name="password2"
              variant="filled"
              fullWidth
              value={data.password2}
              onChange={(e) => setData({ ...data, password2: e.target.value })}
              error={!!errors?.password2}
              helperText={errors?.password2 && errors?.password2?.message}
              placeholder={t('Repeat new password')}
            />

            <Button
              color="primary"
              variant="contained"
              size="large"
              fullWidth
              component={Button}
              onClick={onSubmit}
              disabled={isLoading}
              sx={{
                pt: '10px',
                pb: '10px',
                mt: 4,
              }}
            >
              Change Password
            </Button>
            {Snackbar}
          </Box>

        </Grid>
      </Grid>
    </Box>


  )
};


export default MyAccountChangePasswordTab;
