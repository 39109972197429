import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    athletes: [],
    athletesListenerInitialised: false
};

export const athletesSlice = createSlice({
    name: 'athletesListener',
    initialState,
    reducers: {
        setAthletes: (state, action) => {
            state.athletes = action.payload
        },
        setAthletesListenerInitialised: (state, action) => {
            state.athletesListenerInitialised = action.payload
        }
    },
});

export const {setAthletes} = athletesSlice.actions;
export const {setAthletesListenerInitialised} = athletesSlice.actions;
export const selectAthletes = (state) => state.athletes;

export default athletesSlice.reducer;
