
import config from '../App.config';
import { getFirestore, doc, getDoc, getDocs, collection, setDoc, updateDoc, query, deleteDoc, startAfter, limit, orderBy } from '@firebase/firestore';
import * as yup from 'yup';
import { makeid } from './Firebase'

// ---  ------------------------------------------------------------------
export const getMyPlans = async (uid) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans`;
    const ref = collection(getFirestore(),path)
    const q = await query(ref);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
        return {...doc.data(), id: doc.id}
    })
}


// ---  ------------------------------------------------------------------
export const getMyPlansPage = async (uid, last) => {
  const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans`;
  const ref = collection(getFirestore(),path)
  let q;
  if (last) {
    q = await query(ref, orderBy('name'), startAfter(last), limit(20));
  } else {
    q = await query(ref, orderBy('name'), limit(20));
  }
  const querySnapshot = await getDocs(q);
  const _last = (querySnapshot.size > 0) ? querySnapshot.docs[querySnapshot.size - 1] : null
  const data = querySnapshot.docs?.map(doc => {
      return {...doc.data(), id: doc.id}
  })
  return {data, last: _last};
}


// ---  ------------------------------------------------------------------
export const getMyPlan = async (uid, id) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${id}`;
    const ref = doc(getFirestore(),path)
    const snap = await getDoc(ref);
    if (snap.data()) {
        const ret = {...emptyPlan(),...snap.data(), id: snap.id}
        const a = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${id}/activities`;
        ret.activities = await getCollection(a)
        addGuidsTo(ret)
        repairSchema(ret)
        return ret;
    } else {
        throw new Error("Entity not found");
    }
}

// ---  ------------------------------------------------------------------
export const addGuidsTo = async (plan) => {
  plan.activities?.forEach(r => {
    r.guid = makeid()
  });
}

const repairSchema = (w) => {
  w.tags = [...w.tags]
}

// ---  ------------------------------------------------------------------
export const putMyPlan = async (uid, data) => {
  
  if (data.id) {
      const a = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${data.id}/activities`;
      await clearCollection(a);
      await putCollection(a, data.activities);
      const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${data.id}`
      const ref = doc(getFirestore(),path)
      const _data = cleanPlan(data);
      await updateDoc(ref, _data)

  } else {
      const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans`
      const ref = doc(collection(getFirestore(),path))
      const _data = cleanPlan(data);
      await setDoc(ref, _data)
      const a = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${ref.id}/activities`;
      await putCollection(a, data.activities);
      
  }
}


// ---  ------------------------------------------------------------------
export const removeMyPlan = async (uid, id) => {
    const path = `${config.FIRESTORE_ROOT}/usersdata/${uid}/myplans/${id}`
    const ref = doc(getFirestore(),path)
    console.debug(`removeMyPlan: ${id}`)
    await deleteDoc(ref)
}


// ---  ------------------------------------------------------------------
export const emptyPlan = () => {
  return {
    name: '',
    description: '',
    type: 'plan',
    tags: [],
    // heroAsset: null,
    // tutorials: [],
    // extras: [],
    activities: [emptyDay()]
  }
}


// ---  ------------------------------------------------------------------
export const emptyDay = () => {
  return {
    guid: makeid(),
    scheduleOrder: 1,
  }
}



// ---  ------------------------------------------------------------------
export const planSchema = (t) => yup
.object()
.shape({
  name: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')).max(255, t('Maximum 255 znaków')),
  description: yup.string(t('Pole jest wymagane')).required(t('Pole jest wymagane')).min(3, t('Minimum 3 znaków')).max(1000, t('Maksimum 1000')),
  activities: yup.array().of(
    yup.object().shape({
      activity: yup.object()/*.required(t('Pole jest wymagane'))*/
    })
  )
})
.required();



// --- clean plan object (before saveing in db) ------------------------------------------------------------------
export const cleanPlan = (plan) => {
  const {
    activities,
    ...ret // assigns remaining keys to a new `ret` object
  } = plan;
  console.debug('Cleaned Plan data:', ret);
  return ret;
}

// --- removes all docs from colleciton  -----------------------------------------------------------------
export const clearCollection = async (path) => {
  const ref = collection(getFirestore(), path)
  const q = await query(ref);
  const querySnapshot = await getDocs(q);
  await Promise.all(querySnapshot.docs.map(doc => {
    return deleteDoc(doc.ref);
  }))
}

// --- generic helper - saves array as collection -----------------------------------------------------------------
export const putCollection = async (path, arr) => {
  await Promise.all(arr?.map(item => {
    const ref = doc(collection(getFirestore(),path))
    return setDoc(ref, item)
  }))
}

// --- generic helper - saves array as collection -----------------------------------------------------------------
export const getCollection = async (path) => {
  const ref = collection(getFirestore(),path)
  const q = await query(ref);
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map(doc => {
      return {...doc.data(), id: doc.id}
  })
}

// --- creates one-day plan from given workout -----------------------------------------------------------------
export const createPlanFromWorkout = (w) => {
  const plan = emptyPlan()
  plan.activities[0].scheduleOrder = 1;
  plan.activities[0].activity = w;
  console.log('createPlanFromWorkout', plan)
  return plan
}

