import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    assets: [],
    assetsListenerInitialised: false
};

export const assetsSlice = createSlice({
    name: 'assetsListener',
    initialState,
    reducers: {
        setAssets: (state, action) => {
            state.assets = action.payload
        },
        setAssetsListenerInitialised: (state, action) => {
            state.assetsListenerInitialised = action.payload
        }
    },
});

export const {setAssets} = assetsSlice.actions;
export const {setAssetsListenerInitialised} = assetsSlice.actions;
export const selectAssets = (state) => state.assets;

export default assetsSlice.reducer;
