import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    tags: [],
    tagsListenerInitialised: false
};

export const tagsSlice = createSlice({
    name: 'tagsListener',
    initialState,
    reducers: {
        setTags: (state, action) => {
            state.tags = action.payload
        },
        setTagsListenerInitialised: (state, action) => {
            state.tagsListenerInitialised = action.payload
        }
    },
});

export const {setTags} = tagsSlice.actions;
export const {setTagsListenerInitialised} = tagsSlice.actions;
export const selectTags = (state) => state.tags;

export default tagsSlice.reducer;
