import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    workouts: [],
    workoutsListenerInitialised: false
};

export const workoutsSlice = createSlice({
    name: 'workoutsListener',
    initialState,
    reducers: {
        setWorkouts: (state, action) => {
            state.workouts = action.payload
        },
        setWorkoutsListenerInitialised: (state, action) => {
            state.workoutsListenerInitialised = action.payload
        }
    },
});

export const {setWorkouts} = workoutsSlice.actions;
export const {setWorkoutsListenerInitialised} = workoutsSlice.actions;
export const selectWorkouts = (state) => state.workouts;

export default workoutsSlice.reducer;
