import React from "react";
import ErrorDialog from "./components/ErrorDialog";

class ErrorBoundaryLocal extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false, message: null };
    }
  
    componentDidMount() {
      window.addEventListener("error", ({error}) => {   
        //console.warn("WINDOW ERROR EVENT catch", error.message)
        //this.setState({ hasError: true, message: error.message })
      });
    }

    static getDerivedStateFromError(error) {
      console.warn('getDerivedStateFromError', error)
      // Update state so the next render will show the fallback UI.
      return { hasError: true, message: error.message };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.error('componentDidCatch', error, errorInfo)
      this.setState({ hasError: true, message: error.message })
    }

    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <ErrorDialog error={this.state.message} onClose={() => this.setState({ hasError: false, message: null })}></ErrorDialog>
      } else {
        return this.props.children
      }
      
    }
  }

  export default ErrorBoundaryLocal